
import React from 'react';
import {Container, Row} from 'shards-react';
import Tabs from '../components/common/Tabs';
import {Directorio,Actividad,Sorteos} from '../components/Subvistas/Contacto';
import Title from '../components/ApexCharts/Title';
import { useI18n } from '../utils/i18n';
import resources from '../translations/contacts.i18n';

const Contacto =()=>{
  const t = useI18n({ resources });

  return (
    <Container>
      <Row noGutters className="page-header py-4" />
      <Tabs
        tabs={[
          {
            label: t('directory'),
            content: <Directorio/>
          },
          // {
          //   label: 'Actividad',
          //   content: <Actividad/>
          // },
          // {
          //   label: 'Sorteos',
          //   content: <Sorteos/>
          // },
        ]}
        />
    </Container>
  );
}

export default Contacto;