import React, { useMemo } from "react";
import Chart from 'react-apexcharts'
import { Card, CardHeader, CardBody } from "shards-react";


const RadarParamsArray = ({ title, labels, series, t }) => {
  const chartData = useMemo(() => {
    return {
      options: {
        colors:['#4FC3B3', '#C22821'],
        chart: {
          dropShadow: {
            enabled: true,
            blur: 1,
            left: 1,
            top: 1
          },
        },
        labels: labels.serie1_labels,
        title: {
          text: ''
        },
        stroke: {
          width: 0
        },
        fill: {
          opacity: 0.3
        },
        markers: {
          size: 0
        }
      },
      series: [
        {
          name: series.serie1_title,
          data: series.serie1_data,
        },
        {
          name: series.serie2_title,
          data: series.serie2_data,
        }
      ]
    }
  }, [t])

  return (
    <Card  className="h-100">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{title}</h6>
      </CardHeader>
      <CardBody className="pt-0">
        <Chart options={chartData.options} series={chartData.series} type="radar" height="350" />
      </CardBody>
    </Card>
  );
}

export default RadarParamsArray;