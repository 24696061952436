import React from "react";
import Chart from 'react-apexcharts'
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { useI18n } from "../../utils/i18n";
import resources from '../../translations/days.i18n';


function generateData(count, yrange) {
  var i = 0;
  var series = [];
  while (i < count) {
    var x = (i + 1).toString();
    var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

    series.push({
      x: x,
      y: y
    });
    i++;
  }
  return series;
}

function searchFor(array,dia){
  var series = [];
  var yrange = {min: -30,max: 55};
  for (let i = 0; i < 24; i++) {
    array.map(cada_dia => {
      if(cada_dia.dia_nombre == dia){
        cada_dia.data.map(cada_hora => {
          if(i == parseInt(cada_hora.hora) ){
            var x = (cada_hora.hora);
            var y = (cada_hora.nps);
            series.push({
              x: x,
              y: y
            });
          }
        })
      }
    })
  }
  return series;
}


const HeatChart = ({title, data}) => {

  const t = useI18n({ resources });

  const optionsChart = {
    options: {
      plotOptions: {
        heatmap: {
          shadeIntensity: 0.1,
          colorScale: {
            ranges: [{
                from: 100,
                to: 100,
                name: t('excellent'),
                color: '#4FC3B3'
              },
              {
                from: 70,
                to: 99,
                name: t('good'),
                color: '#E9C563'
              },
              {
                from: 51,
                to: 69,
                name: t('regular'),
                color: '#DB8916'
              },
              {
                from: -100,
                to: 50,
                name: t('bad'),
                color: '#C22821'
              }
            ]
          }
        }
      },
      dataLabels: { enabled: false },
    },
    series: [
      {
        name: t('sunday'),
        data: searchFor(data,'Sun')
      },
      {
        name: t('saturday'),
        data: searchFor(data,'Sat')
      },
      {
        name: t('friday'),
        data: searchFor(data,'Fri')
      },
      {
        name: t('thursday'),
        data: searchFor(data,'Thu')
      },
      {
        name: t('wednesday'),
        data: searchFor(data,'Wed')
      },
      {
        name: t('tuesday'),
        data: searchFor(data,'Tue')
      },
      {
        name: t('monday'),
        data: searchFor(data,'Mon')
      },
    ],
  }


  return (
    <Card small className="h-100">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{title}</h6>
      </CardHeader>
      <CardBody className="pt-0">
          <Chart options={optionsChart.options} series={optionsChart.series} type="heatmap" height='270px' />
      </CardBody>
    </Card>
  );
}
export default HeatChart;
