import React, { useState, useEffect } from 'react';
import _ from 'lodash';
// Components
import { Row, Col, Card,Button,FormInput,FormSelect,  DatePicker,InputGroup} from 'shards-react';
import { Modal } from 'antd';
import MaterialTable,  { MTableToolbar } from 'material-table';
import {  Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {getToday,toLocaleDateString} from '../../utils/date';
import { useI18n } from '../../utils/i18n';
import resources from '../../translations/tickets.i18n';


export const ColumnClient = ({ row }) => {
  const nombres = [];
  const nulls = ['0', 'null', null, '', undefined];
  if (!nulls.includes(row.nombre)) nombres.push(row.nombre);
  if (!nulls.includes(row.apellido)) nombres.push(row.apellido);

  return (
    <div>
      <div>{`${nombres.length ? nombres.join(' ') : ''}`}</div>
      {!nulls.includes(row.mail) ? (
        <div style={{ whiteSpace: 'nowrap' }}>
          <i className="material-icons">email</i> {row.mail}
        </div>
      ) : null}
      {!nulls.includes(row.telefono) ? (
        <div style={{ whiteSpace: 'nowrap' }}>
          <i className="material-icons">phone</i> {row.telefono}
        </div>
      ) : null}
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  }
});

const TablaVotos = ({
  title = 'Detalle de votos',
  columns,
  data,
  commentEditionEnabled = true,
  tickets=false,
}) => {
  const [date,setDate]=useState(null);
  const [visible,setVisible]=useState(false);
  const [datos,setData]=useState(data);
  const classes = useStyles();
  const [pageSize, setPageSize] = React.useState(
    localStorage.getItem('detalles_pageSize')
      ? Number(localStorage.getItem('detalles_pageSize'))
      : 5
  );

  const [prioridad,setPrioridad]=useState(null);
  const [categoria,setCategoria]=useState(null);
  const [responsable,setResponsable]=useState(null);
  const [vto, setVto]=useState(null);
  const [asunto,setAsunto]=useState(null);
  const [cliente,setCliente]=useState(null);
  // const [notas,setNotas]=useState(null);
  const [tag,setTag]=useState(null);

  const t = useI18n({ resources })


  useEffect(() => {
    setData(data);

  }, [data])



  function onRowAdd(){

    let fecha=getToday()
    let date=fecha[1].setDate(fecha[1].getDate());

    const newData={
      created_at:toLocaleDateString(new Date(date)),
      estado:{nombre:"ABIERTO",color:'#ff0000'},
      prioridad:prioridad,
      event:categoria,
      resp:responsable,
      asunto:asunto,
      tag:[{nombre:tag}],
      vto:{fecha:vto,color:'cyan'},
      ids:datos[datos.length-1].ids+1,
      nombre:cliente
    }
    setData([...datos, newData]);

    setVisible(false);

  }

  function handleStartDateChange(value) {
    setDate(value);

    let fecha=value
    var dd = fecha.getDate();
    var mm = fecha.getMonth() + 1;
    if(dd<10){
      dd="0"+dd;
    }
    if(mm<10){
      mm="0"+mm;
    }
    fecha=dd+"/"+mm;
    setVto(fecha);

  }
  const rest = {};

  if(tickets==true){
    Object.assign(rest, {
      Toolbar: props => (
        <div>
          <MTableToolbar {...props}/>
          <div style={{padding: '0px 10px'}}>
            <Button onClick={()=>setVisible(true)}>{t('create-ticket')}</Button>
          </div>
        </div>
      ),

    })

  }
  // if (commentEditionEnabled) {
  //   Object.assign(rest, {
  //     editable: {
  //       onRowUpdate: (newData, oldData) =>
  //         new Promise((resolve, reject) => {
  //           setTimeout(() => {
  //             {
  //               const updated = [...data];
  //               const index = updated.indexOf(oldData);
  //               updated[index] = newData;
  //               this.setState({ data: updated }, () => resolve());

  //             }
  //             resolve();
  //           }, 1000);
  //         })
  //     }
  //   });
  // }

  return (

    <Paper className={classes.root}>
      <Modal

          title={t('register-ticket')}
          visible={visible}
          onCancel={() => setVisible(false)}
          onOk={()=>onRowAdd()}
          centered={true}
          width="400px"
          style={{ top: 20 }}
        >
          <div style={{ display:"inline-flex" }}>

            <div >
              <div>
              <p>{t('priority')}:</p>
              <p style={{ marginTop: '42px'}}>{t('category')}: </p>
              <p style={{ marginTop: '42px'}}>{t('responsable')}:</p>
              <p style={{ marginTop: '42px'}}>{t('expiration_date')}:</p>
              <p style={{ marginTop: '42px'}}>{t('subject')}: </p>
              <p style={{ marginTop: '42px'}}>{t('client')}: </p>
              <p style={{ marginTop: '42px'}}>Tag: </p>
              <p style={{ marginTop: '42px'}}>{t('notes')}:</p>
              </div>
            </div>
            <div>
            <div style={{ marginLeft:'25px' }}>
            <p >
            <FormSelect  onChange={e=>setPrioridad(e.target.value)}
            >
              <option value="0"> </option>
                <option value="Alta">{t('high')}</option>
                <option value="Media">{t('medium')}</option>
                <option value="Baja">{t('low')}</option>
            </FormSelect></p>
           <p > <FormInput value={categoria} onChange={e=>setCategoria(e.target.value)}
          ></FormInput></p>
          <p ><FormSelect onChange={e=>setResponsable(e.target.value)}
            >
              <option value="0">{t('select-responsable')}</option>
                <option value="Agustin">Agustin</option>
                <option value="Adan">Adan</option>
                <option value="Ernesto">Ernesto</option>
            </FormSelect></p>
            <p ><InputGroup
        style={{
          flexWrap: 'inherit',

        }}
      >
          <DatePicker
          selected={date}
          onChange={e=>handleStartDateChange(e)}
          placeholderText={t('select-date')}
          dropdownMode="select"
          className="text-center"
          width="165px"
        />
        </InputGroup></p>
        <p style={{ }}><FormInput  value={asunto} onChange={e=>setAsunto(e.target.value)}
          ></FormInput></p>
           <p ><FormInput value={cliente} onChange={e=>setCliente(e.target.value)}
          ></FormInput></p>
          <p ><FormInput value={tag} onChange={e=>setTag(e.target.value)}
          ></FormInput></p>
         <p ><FormInput
          ></FormInput></p></div>
            </div>
          </div>
        </Modal>

        <MaterialTable
          title={''}
          columns={columns}
          options={{
            pageSize
          }}
          data={datos}
          onChangeRowsPerPage={pageSize => {
            setPageSize(pageSize);
            localStorage.setItem('detalles_pageSize', pageSize);
          }}
          localization={{
            toolbar: {
              searchPlaceholder: t('search')
            },
            pagination: {
              labelRowsSelect: t('rows')
            }
          }}
          components={
            rest

          }




          // actions={[
          //   rowData => ({
          //     tooltip: 'Agregar Comentarios a Informe',
          //     icon: selection.includes(rowData) ? 'remove' : 'add',
          //     onClick: (event, row) => {
          //       const included = selection.includes(row);
          //       const rows = selection.filter(entry => entry !== row);
          //       if (!included) rows.push(row);
          //       setSelection(rows.slice(0, 50));
          //     }
          //   }),
          // ]}



        />

    </Paper>
  );
};

export const DetailsTable = ({datos,columnsBefore,sinHora=false}) => {
  const [votes, setVotes] = useState([]);
  const [details, setDetails] = useState([]);
  const [columns, setColumns] = useState([]);
  let cont=0;

  const t = useI18n({ resources })

  useEffect(() => {
    const parsed = votes.map(element => {

      let fecha=getToday();
      let date=fecha[1].setDate(fecha[1].getDate() - cont);
      let seg=Math.floor(Math.random() * (60 - 0)) + 0;
      let hora=Math.floor(Math.random() * (24 - 0)) + 0;
      let min=Math.floor(Math.random() * (60 - 0)) + 0;
      if(seg<10){
        seg="0"+seg;
      }
      if(hora<10){
        hora="0"+hora;
      }
      if(min<10){
        min="0"+min;
      }
      if(sinHora){
        element.created_at=toLocaleDateString(new Date(date));
      }else{
      element.created_at=toLocaleDateString(new Date(date))+ " "+hora+":"+min+":"+seg;
      }
      const resultadoEntry = {
        ...element
      };

      cont++;
      Object.keys(resultadoEntry).forEach(key => {
        if (
          resultadoEntry[key] === null ||
          [
            'id',
            'encuesta_id',
            'empresa_id',
            'punto_de_venta_id',
            'sucursal_id',
            'tipo_dispositivo_id'
          ].includes(key)
        ) {
          delete resultadoEntry[key];
        }
      });

     if(element.votos){
      (element.votos || []).forEach((voto, index) =>
        Object.assign(resultadoEntry, {
          [`pregunta${index}`]: voto.pregunta,
          [`voto${index}`]: voto.voto,
          [`motivo${index}`]: voto.motivo,
        })
      );
     }
      delete resultadoEntry.votos;
      return resultadoEntry;
    });

    setDetails(parsed);
  }, [votes]);

  useEffect(() => {
    const currentColumns = [
      ...columnsBefore
    ];
   if (votes && votes[0]) {
      votes[0].votos.forEach((voto, index) => {
        currentColumns.push({
          title: voto.pregunta,
          field: 'voto' + index
        });
        if (voto.motivo === 'unico') return;
        currentColumns.push({
          title: t('reason'),
          field: 'motivo' + index
        });
      });
    }
    setColumns(currentColumns);
  }, [votes]);

  useEffect(() => {
    setVotes(datos.votes);
  });

  return (
    <Row>
      <Col>
          <Card lg="12" className="mb-4" style={{ minWidth: '300px',marginTop:'5px' }}>
            <TablaVotos columns={columns} data={details} tickets={sinHora}/>
          </Card>
      </Col>
    </Row>
  );
};

export default DetailsTable;
