export default {
    en: {
        'sent': 'Sent',
        'open': 'Open',
        'answered-surveys': 'Answered surveys',
        'answered': 'Answered'
    },
    es: {
        'sent': 'Enviados',
        'open': 'Abiertos',
        'answered-surveys': 'Encuestas respondidas',
        'answered': 'Respondidos'
    },
    pt: {
        'sent': 'Enviadas',
        'open': 'Abertas',
        'answered-surveys': 'Pesquisas respondidas',
        'answered': 'Respondidas'
    }
}