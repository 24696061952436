export default {
    en: {
        'total-votes': 'Total of Votes',
        'stores': 'Stores',
        'area':'Area',
        'general-total': 'General Total',
        'turn-results': 'Results per turn',
        'distribution-day-hour' :'Distribution per day and hour',
        'services-area': 'Service stations category',
        'detractors': 'Detractors',
        'speed': 'Speed',
        'recommend-us': 'Would you recommend us?',
        'excellent': 'Excellent',
        'quickly-found': 'I quickly found what I was looking for',
        'specific': 'Very specific',
        'others': 'Others',
        'easy-navigation': 'Navigation is very easy',
        'advice': 'Advice',
        'good-page': 'The page is very good',
        'good-attention': 'Very good staff service',
        'attention': 'Attention',
        'suggestions': 'Suggestions',
        'garbage-options': 'You should provide options to separate garbage',
        'offers': 'Offers',
        'prices': 'Prices',
        'date':'Date',
        'client':'Client',
        'comment': 'Comment',
        'comments': 'Comments',
        'morning':'Morning',
        // 'best-hour':'Best hour',
        // 'worst-hour': 'Worst hour',
        'afternoon': 'Afternoon',
        'night': 'Night',
        'good': 'Good',
        'regular':'Regular',
        'bad': 'Bad',
        'branch-office': 'Branch office',
        'movies': 'Movies',
        'simple': 'Simple'
    },
    es: {
        'total-votes': 'Total de Votos',
        'stores': 'Tiendas', // o sucursales?
        'area':'Rubro',
        'general-total': 'Total General',
        'turn-results': 'Resultados por turno',
        'distribution-day-hour' :'Distribución por día y hora',
        'services-area': 'Rubro estaciones de servicios',
        'detractors': 'Detractores',
        'speed': 'Velocidad',
        'recommend-us': '¿Nos recomendarías?',
        'excellent': 'Excelente',
        'quickly-found': 'Encontré rápido lo que buscaba',
        'specific': 'Muy concreto',
        'others': 'Otros',
        'easy-navigation': 'Es muy fácil la navegación',
        'advice': 'Asesoramiento',
        'good-page': 'Muy buena la página',
        'good-attention': 'Muy buena la atención del personal',
        'attention': 'Atención',
        'suggestions': 'Sugerencias',
        'garbage-options': 'Deberían brindar opciones para separar la basura',
        'offers': 'Ofertas',
        'prices': 'Precios',
        'date':'Fecha',
        'client':'Cliente',
        'comment': 'Comentario',
        'comments': 'Comentarios',
        'morning':'Mañana',
        'good': 'Bueno',
        'regular':'Regular',
        'bad':'Malo',
        // 'best-hour':'Mejor hora',
        // 'worst-hour': 'Peor hora',
        'afternoon': 'Tarde',
        'night': 'Noche',
        'branch-office': 'Sucursal',
        'movies': 'Cine',
        'simple': 'Simple'
    },
    pt: {
        'total-votes': 'Votos totais',
        'stores': 'Filiais',
        'area': 'Área',
        'general-total': 'Total Geral',
        'turn-results': 'Resultados por turno',
        'distribution-day-hour' :'Distribuição por dia e hora',
        'services-area': 'Categoria de estações de serviço', // o Area?
        'detractors': 'Detratores',
        'speed': 'Rapidez',
        'recommend-us': 'Você nos recomendaria?',
        'excellent': 'Excelente',
        'quickly-found': 'Rapidamente encontrei o que procurava',
        'specific': 'Muito específico',
        'others': 'Outras',
        'easy-navigation': 'A navegação é muito fácil',
        'advice': 'Conselho',
        'good-page': 'A página é muito boa',
        'good-attention': 'Muito bom atendimento da equipe',
        'attention': 'Atenção',
        'suggestions': 'Sugestões',
        'garbage-options': 'Vocês deviam ter opção pra separa o lixo',
        'offers': 'Ofertas',
        'prices': 'Preços',
        'date':'Data',
        'client':'Cliente',
        'comment': 'Comentário',
        'comments': 'Comentários',
        'morning':'Manhã',
        // 'best-hour':'Melhor tempo',
        // 'worst-hour': 'Pior tempo',
        'afternoon': 'Tarde',
        'night': 'Noite',
        'good': 'Bom',
        'regular':'Regular',
        'bad':'Ruim',
        'branch-office': 'Filial',
        'movies': 'Filmes',
        'simple': 'Simples'
    }
}