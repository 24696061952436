import { Container, Row, Col,Card,CardHeader } from "shards-react";
// import PageTitle from "./../components/common/PageTitle";
import {DetailsTable,ColumnClient} from "./../components/ApexCharts/VotesDetails";
import { Tag } from 'antd';



//Graficos

import RadarParamsArray from "../components/ApexCharts/RadarParamsArray";
// import Radar from "../components/ApexCharts/Radar";
import Circle from "../components/ApexCharts/Circle";
import BarChart from "../components/ApexCharts/BarChart";
import Title from './../components/ApexCharts/Title';
import Buble from '../components/Jquery/buble';
import { useMobile } from '../utils/dom';
import { useI18n } from "../utils/i18n";
import resources from '../translations/surveys.i18n';

/*
import HeatChart from "../components/ApexCharts/HeatChart";
import LineWithBar from "../components/ApexCharts/LineWithBar";
import StackedBar from "../components/ApexCharts/StackedBar";
import Column from "../components/ApexCharts/Column";
*/

//Caritas
/*
import carita_excelente from '../assets/excelente.png';
import carita_bueno from '../assets/bueno.png';
import carita_regular from '../assets/regular.png';
import carita_mal from '../assets/mal.png';
*/

//CSS

const pregunta1 = {'nps':70};
const pregunta2 = {'nps':40};
const pregunta3 = {'nps':80};


const Encuesta = () => {
  const isMobile = useMobile();
  const t = useI18n({ resources });

  const series_radar1 = { serie1_title: 'Promotores', serie1_data: [24, 45, 20, 10], serie2_title: t('detractors'), serie2_data: [35, 2, 10, 40] }
  const label_radar1 = {serie1_labels:[t('empathy'), t('speed'), t('advice'), t('others')]};

  const series_radar2 = { serie1_title: 'Promotores', serie1_data: [25, 15, 30, 10], serie2_title: t('detractors'), serie2_data:[30, 30, 10, 10] };
  const label_radar2 = { serie1_labels:[t('cleaning'), t('smells'), t('supplies'), t('others')]};

  const series_radar3 = { serie1_title: 'Promotores', serie1_data: [25, 15, 40, 10,45], serie2_title: t('detractors'), serie2_data:[45, 45, 10, 5, 15] };
  const label_radar3 = { serie1_labels: [t('price'), t('location'), t('variety-of-products'), t('others'), t('attention')]};

  const columnsBefore = [
    {title: t('date'), field:'created_at'},
    {title: t('client'), field: 'nombre', render: row => <ColumnClient row={row} />},
    {title: t('comment'), field: 'comentario', editable: 'onUpdate'},
    {
      title:'Tags',
      field:'tag',
      render: row => row.tag ? row.tag.map((tag, index) => (<Tag key={index} style={{ marginTop:'5px' }} color={tag.color}>{tag.nombre}</Tag>)) : null,
      cellStyle: { justifyContent:'center', textAlign:'center' },
      headerStyle:{ textAlign: 'center' }
    },
  ];

  const datos = {
    columnas:[],
    votes: [
      {
        punto_de_venta: "CAJA",
        sucursal: "SHOPPING",
        Operario: null,
        has_user_context: 0,
        nombre: t('name-customer-1'),
        apellido: t('last-name-customer-1'),
        mail: "quinta@gmail.com",
        telefono: 3732642387,
        encuesta_datos_id: 593,
        tipo_dato: null,
        is_first_visit: null,
        referral: null,
        created_at: "2019-11-28 11:36:48",
        fecha_operacion: null,
        votos_ids: null,
        votos:[{ voto_id: 2, voto: t('good'), pregunta: t('recommend-us'), motivo: t('others') }],
        preguntas: null,
        motivos: null,
        comentario: t('shame-wifi'),
        tag:[{ nombre:"Wifi",color:"blue" }]
      },
      {
        punto_de_venta: "CAJA",
        sucursal: "SHOPPING",
        Operario: null,
        has_user_context: 0,
        nombre: t('name-customer-2'),
        apellido: t('last-name-customer-2'),
        mail: "arpep@gmail.com",
        telefono: 459039485,
        encuesta_datos_id: 593,
        tipo_dato: null,
        is_first_visit: null,
        referral: null,
        created_at: "2019-11-28 11:36:48",
        fecha_operacion: null,
        votos_ids: null,
        votos: [{ voto_id: 1, voto: t('excellent'), pregunta: t('recommend-us'), motivo: t('variety-of-products')}],
        preguntas: null,
        motivos: null,
        comentario: t('good-movie'),
        tag: [{ nombre: t('movies'), color:"red" }]
      },
      {
        punto_de_venta: "CAJA",
        sucursal: "SHOPPING",
        Operario: null,
        has_user_context: 0,
        nombre:  t('name-customer-3'),
        apellido: t('last-name-customer-3'),
        mail: "jp@gmail.com",
        telefono: 49348029,
        encuesta_datos_id: 593,
        tipo_dato: null,
        is_first_visit: null,
        referral: null,
        created_at: "2019-11-28 11:36:48",
        fecha_operacion: null,
        votos_ids: null,
        votos:[{ voto_id: 3, voto: "Regular", pregunta: t('recommend-us'), motivo: t('prices')}],
        preguntas: null,
        motivos: null,
        comentario: t('popcorn-expensive'),
        tag:[{nombre: t('movies'), color:'red' }, { nombre: t('prices'), color:'green' }]
      },
      {
        punto_de_venta: "CAJA",
        sucursal: "SHOPPING",
        Operario: null,
        has_user_context: 0,
        nombre: t('name-customer-4'),
        apellido: t('last-name-customer-4'),
        mail: "gLop@hotmail.com",
        telefono: 948938493,
        encuesta_datos_id: 593,
        tipo_dato: null,
        is_first_visit: null,
        referral: null,
        created_at: "2019-11-28 11:36:48",
        fecha_operacion: null,
        votos_ids: null,
        votos:[{ voto_id: 3, voto: "Regular", pregunta: t('recommend-us'), motivo: t('others')}],
        preguntas: null,
        motivos: null,
        comentario: t('very-cold'),
        tag:[{ nombre:"Ambientacion",color:"cyan" }]
      },
      {
        punto_de_venta: "CAJA",
        sucursal: "SHOPPING",
        Operario: null,
        has_user_context: 0,
        nombre: null,
        apellido: null,
        mail: null,
        telefono: null,
        encuesta_datos_id: 593,
        tipo_dato: null,
        is_first_visit: null,
        referral: null,
        created_at: "2019-11-28 11:36:48",
        fecha_operacion: null,
        votos_ids: null,
        votos: [{ voto_id: 4, voto: t('bad'), pregunta: t('recommend-us'), motivo: t('prices')}],
        preguntas: null,
        motivos: null,
        comentario: t('put-discounts'),
        tag: [{ nombre: t('offers'), color: 'lime' }]
      },
      {
        punto_de_venta: "CAJA",
        sucursal: "SHOPPING",
        Operario: null,
        has_user_context: 0,
        nombre: null,
        apellido: null,
        mail: null,
        telefono: null,
        encuesta_datos_id: 593,
        tipo_dato: null,
        is_first_visit: null,
        referral: null,
        created_at: "2019-11-28 11:36:48",
        fecha_operacion: null,
        votos_ids: null,
        votos: [{ voto_id: 2,voto: t('good'), pregunta: t('recommend-us'), motivo: t('others')}],
        preguntas: null,
        motivos: null,
        comentario: t('garbage-options'),
        tag:[{ nombre: t('suggestions'), color:'green' }]
      },
      {
        punto_de_venta: "CAJA",
        sucursal: "SHOPPING",
        Operario: null,
        has_user_context: 0,
        nombre: null,
        apellido: null,
        mail: null,
        telefono: null,
        encuesta_datos_id: 593,
        tipo_dato: null,
        is_first_visit: null,
        referral: null,
        created_at: "2019-11-28 11:36:48",
        fecha_operacion: null,
        votos_ids: null,
        votos: [{ voto_id: 1, voto: t('excellent'), pregunta: t('recommend-us'), motivo: t('attention') }],
        preguntas: null,
        motivos: null,
        comentario: t('good-attention'),
        tag: [{ nombre: t('attention'), color:'purple' }]
      }
      ]};

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Title/>

      <Row>
        <Col lg="4" md="6" sm="12" className="mb-4" >
          <Card>
            <CardHeader className="border-bottom">
              <h6 className="m-0"> {t('care-evaluation')} </h6>
            </CardHeader>
          <Circle data={pregunta1.nps} title_circle='NPS' title='Como evaluas Atención' size='50%' />
          </Card>
        </Col>

        <Col lg="4" md="6" sm="12" className="mb-4">
          <Card>
            <CardHeader className="border-bottom">
              <h6 className="m-0"> {t('bathroom-evaluation')} </h6>
            </CardHeader>
          <Circle data={pregunta2.nps} title_circle='NPS' title='Como evaluas nuestros Baños' size='50%' />
          </Card>
        </Col>

        <Col lg="4" md="6" sm="12" className="mb-4">
          <Card>
              <CardHeader className="border-bottom">
                <h6 className="m-0"> {t('recommend-us')} </h6>
              </CardHeader>
            <Circle data={pregunta3.nps} title_circle='NPS' title='Nos recomendarías' size='50%' />
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg="4" md="4" sm="12" className="mb-4" >
          <RadarParamsArray series={series_radar1} labels={label_radar1} t={t} />
        </Col>

        <Col lg="4" md="4" sm="12" className="mb-4">
          <RadarParamsArray series={series_radar2} labels={label_radar2} t={t} />
        </Col>

        <Col lg="4" md="4" sm="12" className="mb-4">
          <RadarParamsArray series={series_radar3} labels={label_radar3} t={t} />
        </Col>
      </Row>

      <Row>
        <Col lg="12"  >
          <BarChart isMobile={isMobile}  />
        </Col>
      </Row>

      <Row style={{ marginTop:'10px' }}>
        <Col lg="12" md="4" sm="12" className="mb-4">
          <Buble isMobile={isMobile}/>
        </Col>
      </Row>

      <Row>
        <Col lg="12" md="4" sm="12" className="mb-4" >
          <DetailsTable datos={datos} columnsBefore={columnsBefore}/>
        </Col>
      </Row>
    </Container>
  );
}

export default Encuesta;