
import React,{ useState, useEffect } from 'react';
import { Card,CardBody,CardHeader } from 'shards-react';
import Chart from 'react-apexcharts';
import { useI18n } from '../../utils/i18n';
import resources from '../../translations/blog-overview.i18n';

const Buble = ({isMobile=false}) => {
  const t = useI18n({ resources })
  const data=[
    {size: '90%', nombre: t('offers'), valores: [70,30,20,10], altura: 200, ancho: 200},
    {size: isMobile ? '93%' : '94%', ancho: isMobile ? 210 : 300, nombre: t('movies'), valores: [70,30,20,10], altura: isMobile ?270 : 320, tam:'20px'},
    {size:'90%', ancho: 200, altura: 200, nombre: "Wifi", valores: [70,30,20,10]}
  ];


  return (
    <Card>
      <CardHeader>
          Tags - {t('comments')}
      </CardHeader>
      <CardBody style={{ display:isMobile?'grid':'flex',justifyContent:'center' }}>
        {data.map((tag, i) => <Component data={tag} key={i} /> )}
      </CardBody>
    </Card>
  );
}

const Component = ({data}) => {

    const [options,setOptions]= useState([]);
    const [series,setSeries]=useState([]);

    useEffect(()=>{
      const series = data.valores;

      const options = {
        labels: [data.nombre,data.nombre,data.nombre,data.nombre],
        colors: ['rgb(79, 195, 179)', 'rgb(233, 197, 99)', 'rgb(219, 137, 22)', 'rgb(194, 40, 33)'],
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              size: data.size,
              labels: {
                show: true,
                value: { show: false },
                name: {
                  show: true,
                  formatter: function (w) {
                      return data.nombre
                    },
                  offsetY: 0,
                  fontSize: data.tam?data.tam:'16px',
                },
                total: {
                  label: data.nombre,
                  showAlways: true,
                  show: true,
                  formatter: function (w) {
                    return ''
                  }
                }
              }
            }
          }
        },
        responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }],
        dataLabels: { enabled: false, enabledOnSeries: false },
        legend: {show: false},
        type: 'donut',
    };

    setSeries(series);
    setOptions(options);


    },[data]);


    if (series.length > 0) {
        return (
            <Chart options={options} series={series} height={data.altura} width={data.ancho} type="donut" />
        );
      } else {
        return null;
      }

}

export default Buble;