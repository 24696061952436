import React, { useState } from 'react';
import { Paper, makeStyles, Tabs, Tab, Typography, Box } from '@material-ui/core';

function TabPanel({ visible, children }) {
  return (
    <div
      style={{
        backgroundColor: 'white',
        display: !visible ? 'none' : undefined,
      }}
      role="tabpanel"
    >
      {children}
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  }
});

const SmileTabs = ({ tabs, query, key = 'tab', preserveContent = true }) => {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState((query && query[key]) ? Number(query[key]) : 0);

  const handleChange = (event, newValue) => {
    setCurrentIndex(newValue);
    if (query) query.merge({
      [key]: newValue,
    });
  };
  const currentTab = tabs[currentIndex];

  return (
    <div>
      <Paper className={classes.root}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px solid #e0e0e0',
        }}>
          <div style={{
            flexGrow: 1,
          }}>
            <Tabs
              value={currentIndex}
              onChange={handleChange}
            >
              {tabs.map((tab, index) => {
                return (
                  <Tab key={index} label={tab.label} />
                )
              })}
            </Tabs>
          </div>
          <div style={{
            margin: '0 8px',
          }}>
            {currentTab && currentTab.actions ? currentTab.actions : null}
          </div>
        </div>
      </Paper>
      {tabs.map((tab, index) => {
        if (!preserveContent && index !== currentIndex) return null;
        return (
          <TabPanel
            key={index}
            visible={index === currentIndex}
          >
            {tab.content}
          </TabPanel>
        )
      })}
    </div>
  );
};

export default SmileTabs;
