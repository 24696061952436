
export function getToday(){
  
  let hoy = new Date();
  var dd = hoy.getDate();
  var mm = hoy.getMonth() + 1; //hoy es 0!
  var yyyy = hoy.getFullYear();

  if (dd < 10) {
    dd = '0' + dd;
  }

  if (mm < 10) {
    mm = '0' + mm;
  }
  hoy = mm + '/' + dd + '/' + yyyy;
  let today = new Date(hoy);
  today.setHours(20);
  today.setMinutes(59);

  return [hoy,today];

}

export function getFromTo() {
  
  let fecha=getToday();
  
  var dias_menos = new Date(fecha[0]);
  dias_menos.setDate(dias_menos.getDate() - 6);
 
  dias_menos.setHours(0);
  return {
    to: fecha[1],
    from: dias_menos,
  }
}

export function toLocaleDateString(date) {
  return date ? date.toLocaleDateString('es-ES') : '';
};
