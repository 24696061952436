export default {
    en:{
      'directory':'Directory',
      'name': 'Name',
      'last-name': 'Last name',
      'identification': 'ID',
      'gender': 'Gender',
      'date-of-birth': 'Date of birth',
      'mail': 'Mail',
      'country': 'Country',
      'last-purchase': 'Last purchase',
      'place-1': 'London',
      'place-2': 'Liverpool',
    },
    es:{
      'directory':'Directorio',
      'name': 'Nombre',
      'last-name': 'Apellido',
      'identification': 'DNI',
      'gender': 'Género',
      'date-of-birth': 'Fecha de nacimiento',
      'mail': 'Mail',
      'country': 'País',
      'last-purchase': 'Última compra',
      'place-1': 'Argentina',
      'place-2': 'Uruguay',
    },
    pt:{
      'directory':'Contatos',
      'name': 'Nome',
      'last-name': 'Sobrenome',
      'identification': 'DNIC',
      'gender': 'Gênero',
      'date-of-birth': 'Data de nascimento',
      'mail': 'E-Mail',
      'country': 'Região',
      'last-purchase': 'Última compra',
      'place-1': 'São Paulo',
      'place-2': 'Rio de Janeiro',
    }
  }