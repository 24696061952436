import React, { useState, useEffect } from 'react';
import {HalfCircles,NPS} from '../RoundSlider';
import DetailsTable from '../ApexCharts/VotesDetails';
import {Perfil} from '../ApexCharts/Perfil';

import { Row, Col } from 'shards-react';
import { useI18n } from '../../utils/i18n';
import resources from '../../translations/contacts.i18n'

export const Directorio = () => {

  const t = useI18n({ resources })

  const datos={
    columnas:[],
    votes:
      [{
        nombre: "Diego",
        apellido: "Maeni",
        dni: "31,246,123",
        mail: "hola@hotmail.com",
        telefono: "42123543",
        fechaNac: "8/26/80",
        genero: "M",
        pais: t('place-1'),
        nps: "100",
        fechaOperacion: "5/21/20",
        reciente: "100",
        frecuente: "100",
        volumen: "100",
        votos:[]
    },
    {
        nombre: "Matilde",
        apellido: "Luzuriaga",
        dni: "95,032,387",
        mail: "mati@gmail.com",
        telefono: "13253234",
        fechaNac: "5/15/60",
        genero: "F",
        pais: t('place-2'),
        nps: "99",
        fechaOperacion: "5/20/20",
        reciente: "96",
        frecuente: "46",
        volumen: "55",
        votos:[]
    },
    {
        nombre: "María Inés",
        apellido: "García",
        dni: "41,987,669",
        mail: "minesgarcia@outlook.com.ar",
        telefono: "54334234",
        fechaNac: "5/25/95",
        genero: "F",
        pais: t('place-1'),
        nps: "44",
        fechaOperacion: "5/20/20",
        reciente: "97",
        frecuente: "74",
        volumen: "21",
        votos:[]
    },
    {
        nombre: "José",
        apellido: "Monte",
        dni: "33,212,521",
        mail: "jmonte@montehnos.com.ar",
        telefono: "4235345",
        fechaNac: "6/7/88",
        genero: "F",
        pais: t('place-1'),
        nps: "87",
        fechaOperacion: "5/16/20",
        reciente: "75",
        frecuente: "96",
        volumen: "76",
        votos:[]
    },
    {
        nombre: "Mariano",
        apellido: "Goza",
        dni: "38,344,544",
        mail: "mgoza@live.com.ar",
        telefono: "8678567",
        fechaNac: "10/5/94",
        genero: "M",
        pais: t('place-1'),
        nps: "56",
        fechaOperacion: "5/16/20",
        reciente: "72",
        frecuente: "58",
        volumen: "34",
        votos:[]
    },
    {
        nombre: "Martín",
        apellido: "Benchimol",
        dni: "8,676,231",
        mail: "mben@yahoo.com",
        telefono: "23598657",
        fechaNac: "1/1/43",
        genero: "M",
        pais: t('place-1'),
        nps: "21",
        fechaOperacion: "5/16/20",
        reciente: "72",
        frecuente: "53",
        volumen: "34",
        votos:[]
    },
    {
        nombre: "Carola",
        apellido: "Migueletti",
        dni: "29,165,313",
        mail: "cami@aol.com",
        telefono: "54678234",
        fechaNac: "11/11/80",
        genero: "F",
        pais: t('place-1'),
        nps: "53",
        fechaOperacion: "5/16/20",
        reciente: "79",
        frecuente: "18",
        volumen: "84",
        votos:[]
    },
    {
        nombre: "Jonathan",
        apellido: "Marino",
        dni: "38,123,123",
        mail: "jomarino@altavista.com",
        telefono: "86757654",
        fechaNac: "12/12/87",
        genero: "M",
        pais: t('place-1'),
        nps: "44",
        fechaOperacion: "5/16/20",
        reciente: "74",
        frecuente: "74",
        volumen: "75",
        votos:[]
    },
    {
        nombre: "Juan Gabriel",
        apellido: "Vicente",
        dni: "31,774,213",
        mail: "jgv@gmail.com",
        telefono: "65567698",
        fechaNac: "8/28/85",
        genero: "M",
        pais: t('place-1'),
        nps: "66",
        fechaOperacion: "5/14/20",
        reciente: "62",
        frecuente: "6",
        volumen: "66",
        votos:[]
    },
    {
        nombre: "Víctor",
        apellido: "Estevia",
        dni: "48,232,656",
        mail: "vicest@hotmail.com.ar",
        telefono: "42855673",
        fechaNac: "1/23/07",
        genero: "M",
        pais: t('place-1'),
        nps: "32",
        fechaOperacion: "5/14/20",
        reciente: "62",
        frecuente: "23",
        volumen: "99",
        votos:[]
    },
    {
        nombre: "Ivana",
        apellido: "Mariana",
        dni: "6,764,132",
        mail: "ivanam@telefonica.com.ar",
        telefono: "56475868",
        fechaNac: "1/4/30",
        genero: "M",
        pais: t('place-1'),
        nps: "21",
        fechaOperacion: "5/14/20",
        reciente: "69",
        frecuente: "88",
        volumen: "45",
        votos:[]
    },
    {
        nombre: "Pablo",
        apellido: "Orellano",
        dni: "37,091,235",
        mail: "porellano@live.com",
        telefono: "75765456",
        fechaNac: "9/26/86",
        genero: "M",
        pais: t('place-1'),
        nps: "66",
        fechaOperacion: "5/13/20",
        reciente: "58",
        frecuente: "79",
        volumen: "4",
        votos:[]
    },
    {
        nombre: "Nelson",
        apellido: "Ortega",
        dni: "36,231,342",
        mail: "nelson231@hotmail.com",
        telefono: "64574567",
        fechaNac: "7/26/85",
        genero: "M",
        pais: t('place-1'),
        nps: "75",
        fechaOperacion: "5/12/20",
        reciente: "46",
        frecuente: "92",
        volumen: "26",
        votos:[]
    },
    {
        nombre: "Micaela",
        apellido: "García",
        dni: "4,124,321",
        mail: "mgarcia1930@yahoo.com.ar",
        telefono: "12325634",
        fechaNac: "3/5/27",
        genero: "F",
        pais: t('place-1'),
        nps: "47",
        fechaOperacion: "4/21/20",
        reciente: "57",
        frecuente: "97",
        volumen: "87",
        votos:[]
    },
    {
        nombre: "Pedro",
        apellido: "Mármol",
        dni: "40,131,317",
        mail: "marmolpedro@gmail.com",
        telefono: "65454645",
        fechaNac: "7/2/95",
        genero: "M",
        pais: t('place-1'),
        nps: "34",
        fechaOperacion: "4/21/20",
        reciente: "53",
        frecuente: "39",
        volumen: "64",
        votos:[]
    },
    {
        nombre: "Edgardo",
        apellido: "Peralta",
        dni: "31,554,453",
        mail: "ed_peralta@losperalta.com.ar",
        telefono: "35345465",
        fechaNac: "8/3/89",
        genero: "M",
        pais: t('place-1'),
        nps: "99",
        fechaOperacion: "4/11/20",
        reciente: "39",
        frecuente: "9",
        volumen: "21",
        votos:[]
    },
    {
        nombre: "Roberto",
        apellido: "Peretti",
        dni: "45,612,383",
        mail: "peretti_robert@gmail.com",
        telefono: "54676654",
        fechaNac: "4/27/80",
        genero: "M",
        pais: t('place-1'),
        nps: "41",
        fechaOperacion: "3/27/20",
        reciente: "23",
        frecuente: "12",
        volumen: "84",
        votos:[]
    },
    {
        nombre: "Ricardo",
        apellido: "Gasparoni",
        dni: "12,323,584",
        mail: "rich4rd0@cod.com.ar",
        telefono: "74657567",
        fechaNac: "3/1/45",
        genero: "M",
        pais: t('place-1'),
        nps: "57",
        fechaOperacion: "3/22/20",
        reciente: "14",
        frecuente: "23",
        volumen: "77",
        votos:[]
    }
  ]};
  const [infoFilter,setInfo] = useState(datos);
  const [personalizado,setPersonalizado]=useState(true);
  const [reciente, setReciente] = useState("0,100");
  const [nps, setNps] = useState("0,100");
  const [frecuente, setFrecuente] = useState("0,100");
  const [volumen, setVolumen] = useState("0,100");

  const columns = [
    {title: t('name'), field:'nombre'},
    {title: t('last-name'), field:'apellido'},
    {title: t('identification'), field:'dni'},
    {title: t('mail'), field:'mail'},
    {title: t('date-of-birth'), field:'fechaNac'},
    {title: t('gender'), field:'genero'},
    {title: t('country'), field:'pais'},
    {title:"NPS", field:'nps'},
    {title: t('last-purchase'), field:'fechaOperacion'},
];


  async function changeData(info,data,p){
    if(info==='nps'){
      setNps(String(data));
    }

    if(info==='frecuente'){
      setFrecuente(String(data));
    }

    if(info==='volumen'){
       setVolumen(String(data));
    }

    if(info==='reciente'){
      setReciente(String(data));
    }
    if(p==false){
        setPersonalizado(true);
    }

  }

  function changeAll(tipo){
    const alta="67,100";
    const media='34,66';
    const bajo='0,33';
    const todo='0,100';

    if(tipo==="Pestrella"){
      setReciente(alta);
      setFrecuente(bajo);
      setVolumen(alta);

    }
    if(tipo==="estrella"){
      setReciente(alta);
      setFrecuente(alta);
      setVolumen(alta);

    }
    if(tipo==="leales"){
      setReciente(todo);
      setFrecuente(alta);
      setVolumen(todo);

    }
    if(tipo==="riesgo"){
      setReciente(bajo);
      setFrecuente(alta);
      setVolumen(alta);

    }
 setPersonalizado(false);

  }

  useEffect(() => {
    filterBy();
  }, [reciente, frecuente, volumen, nps, t])

  function filterBy(){

    let nuevaInfo={votes:[]};
    let n=nps.split(',');
    let r=reciente.split(',');
    let f=frecuente.split(',');
    let v=volumen.split(',');

    datos.votes.forEach((info,i)=>{
      if(parseInt(info.reciente)>parseInt(r[0]) && parseInt(info.reciente)<parseInt(r[1])){
        if(parseInt(info.frecuente)>parseInt(f[0]) && parseInt(info.frecuente)< parseInt(f[1])){
          if(parseInt(info.volumen)>parseInt(v[0]) && parseInt(info.volumen)< parseInt(v[1])){
            if(parseInt(info.nps)> parseInt(n[0]) && parseInt(info.nps)< parseInt(n[1])){
              nuevaInfo.votes.push(info);
            }
          }
        }
      }
    })
    setInfo(nuevaInfo);
  }


  return (
    <React.Fragment>
      <Row style={{ justifyContent:'center' }}>
        <Col style={{marginTop:'10px'}} lg="3">
          <NPS nps={nps} onChange={(info,data)=>changeData(info,data)}/>
        </Col>
        <Col style={{marginTop:'10px'}}lg="6" >
          <HalfCircles
            reciente={reciente}
            personalizado={personalizado}
            frecuente={frecuente}
            volumen={volumen}
            onChangeTipo={value=>changeAll(value)}
            onChange={(info,data,p)=>changeData(info,data,p)}
          />
        </Col>
        {infoFilter ? (
          <Col lg="12">
            <DetailsTable datos={infoFilter} columnsBefore={columns}></DetailsTable>
          </Col>
        ) : null}
      </Row>
    </React.Fragment>
  );
}


export const Actividad = () => {
  return (
    <React.Fragment>
      <Perfil />
    </React.Fragment>
  );
}

export const Sorteos = () => {
  return (
    <div>
    </div>
  )
}
