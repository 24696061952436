import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Chart from 'react-apexcharts';
import { useI18n } from '../../utils/i18n';
import resources from '../../translations/apex-charts.i18n';
import { Card,Col, CardHeader, CardBody } from 'shards-react';
import '../../styles/area.css';




class CircleCompilado extends React.Component {
  constructor(props) {
    super(props);
    this.state = { options: {}, series: [], labels: [] };
  }

  UNSAFE_componentWillReceiveProps(next_props) {
    this.setState({
      options: {
        labels: [
          'GRUPO:' + [next_props.npsTipo[1]],
          next_props.np2[1] + ': ' + [next_props.npsTipo[0]]
        ],

        legend: {
          show: true,
          position: 'bottom',
          fontSize: '15px',
          fontFamily: 'Helvetica, Arial',
          formatter: undefined,
          offsetX: 0,
          offsetY: 20,
          labels: {
            colors: undefined,
            useSeriesColors: false
          }
        }
      },
      dataLabels: {
        enabled: false,
        enabledOnSeries: false
      },
      series: [[next_props.npsTipo[1]], [next_props.npsTipo[0]]]
    });
  }

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        labels={this.state.labels}
        height="380px"
        type="radialBar"
        className={'my-chart-leyend2'}
      />
    );
  }
}

export const NPSCompilado = () => {
  const [data, setData] = useState(undefined);

  const t = useI18n({ resources });

  const datos = {
    gral: [
      [{ nps: "68", nombre_rubro: `${t('sector')} 1` }],
      [{ nps: "55", nombre_rubro: `${t('sector')} 2` }]
    ],
    empresa: [
      [{ nps: "56", nombre_rubro: `${t('sector')} 1` }],
      [{ nps: "50", nombre_rubro: `${t('sector')} 2` }]
    ],
    indicador: 1
  };

  useEffect(() => {
    const assigned = {
      nps_rubro: [],
      desc_nps: [],
      nps_rubro2: [],
      desc_nps2: [],
    };

    setData(undefined);

    if (datos) {
      if (!datos.indicador) {
        if (datos.gral[0]) {
          Object.assign(assigned, {
            nps_rubro: [
              _.get(datos, 'gral[0].nps'),
              _.get(datos, 'empresa[0].nps'),
            ],
            desc_nps: [datos.gral[0].nombre_rubro, 'RUBRO'],
          });
        }
      } else {
        if (datos.gral[0][0] !== undefined) {
          Object.assign(assigned, {
            nps_rubro: [
              _.get(datos, 'gral[0][0].nps'),
              _.get(datos, 'empresa[0][0].nps'),
            ],
            desc_nps: [datos.gral[0][0].nombre_rubro, t('area').toUpperCase()],
            nps_rubro2: [
              _.get(datos, 'gral[1][0].nps'),
              _.get(datos, 'empresa[1][0].nps'),
            ],
            desc_nps2: [datos.gral[1][0].nombre_rubro, t('area').toUpperCase()]
          });
        }
      }
    }

    setData(assigned);
  }, [t]);

  if (!data) return null;

  return (
    <>
      {data.nps_rubro[0] !== undefined ? (
        <Col lg="6" md="12" sm="12">
          <Card style={{ marginBottom:"10px", minWidth: '300px' }}>
            <CardHeader>
              <h6>NPS {t('average-of')} {data.desc_nps[0]}</h6>
            </CardHeader>
            <CardBody
              style={{
                paddingLeft: '0px',
                paddingRight: '0px',
                paddingBottom: '0px', // podria agregar ?
                paddingTop: '0px'
              }}
            >
              <CircleCompilado
                npsTipo={data.nps_rubro}
                np2={data.desc_nps}
              />
            </CardBody>
          </Card>
        </Col>
      ) : null}
      {data.nps_rubro2[0] !== undefined ? (
        <Col lg="6" md="12" sm="12">
          <Card style={{ minWidth: '300px' }}>
            <CardHeader>
              <h6>NPS {t('average-of')} {data.desc_nps2[0]}</h6>
            </CardHeader>
            <CardBody
              style={{
                paddingLeft: '0px',
                paddingRight: '0px',
                paddingBottom: '0px',
                paddingTop: '0px'
              }}
            >
              <CircleCompilado
                npsTipo={data.nps_rubro2}
                np2={data.desc_nps2}
              />
            </CardBody>
          </Card>
        </Col>
      ) : null}
    </>
  );
}

export default NPSCompilado;
