export default {
    en: {
        'monday':'Monday',
        'tuesday': 'Tuesday',
        'wednesday':'Wednesday',
        'thursday':'Thursday',
        'friday':'Friday',
        'saturday':'Saturday',
        'sunday':'Sunday',
        'excellent': 'Excellent',
        'good': 'Good',
        'regular':'Regular',
        'bad': 'Bad',
        'morning':'Morning',
        // 'best-hour':'Best hour',
        // 'worst-hour': 'Worst hour',
        'afternoon': 'Afternoon',
        'night': 'Night',
    },
    es: {
        'monday':'Lunes',
        'tuesday': 'Martes',
        'wednesday':'Miércoles',
        'thursday':'Jueves',
        'friday':'Viernes',
        'saturday':'Sábado',
        'sunday':'Domingo',
        'excellent': 'Excelente',
        'good': 'Bueno',
        'regular':'Regular',
        'bad':'Malo',
        'morning':'Mañana',
        // 'best-hour':'Mejor hora',
        // 'worst-hour': 'Peor hora',
        'afternoon': 'Tarde',
        'night': 'Noche',
    },
    pt: {
        'monday':'Segunda-feira',
        'tuesday': 'Terça-feira',
        'wednesday':'Quarta-feira',
        'thursday':'Quinta-feira',
        'friday':'Sexta-feira',
        'saturday':'Sabado',
        'sunday':'Domingo',
        'excellent': 'Excelente',
        'good': 'Bom',
        'regular':'Regular',
        'bad':'Ruim',
        'morning':'Manhã',
        // 'best-hour':'Melhor tempo',
        // 'worst-hour': 'Pior tempo',
        'afternoon': 'Tarde',
        'night': 'Noite',
    }
}