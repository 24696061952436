export default {
    en: {
      'spanish': 'Spanish',
      'portuguese': 'Portuguese',
      'english': 'English',
      'logout':'Logout',
      'languages':'Languages',
    },
    es: {
      'spanish': 'Español',
      'portuguese': 'Portugues',
      'english': 'Inglés',
      'languages':'Idiomas',
    },
    pt: {
      'spanish': 'Espanhol',
      'portuguese': 'Português',
      'english': 'Inglês',
      'logout':'Fechar Sessão',
      'languages':'Idiomas',
    }
  }