import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { Container, Row, Col,Card,CardHeader,CardBody } from "shards-react";
import {RankingCard} from "./../components/ApexCharts/RankingCard";
import {NPSCompilado} from "./../components/ApexCharts/CircleCompilado";
import Title from "./../components/ApexCharts/Title";
import { useMobile } from '../utils/dom';

const Compilado= ({ smallStats }) => {

return(
    <Container>
        
        <Title/>
        
        <Row>
        <RankingCard/>
        </Row>
        <Row>
        <NPSCompilado/>
        </Row>
    </Container>
)



}



export default Compilado;