import React from "react";
import Chart from 'react-apexcharts';
import { Card, CardHeader, CardBody,  } from "shards-react";
import { useI18n } from "../../utils/i18n";
import resources from '../../translations/mailing.i18n';
import '../../styles/area.css';


const SparklinesCharts = () => {

  const t = useI18n({ resources })

  const optionsChart = {
    seriesTopSpark1: [{
      data: [350,270,280,280,435,325,450]
    }],
    seriesTopSpark2: [{
      data: [50,80,140,75,100,190,225]
    }],
    seriesTopSpark3: [{
      data: [35,27,69,49,26,79,45]
    }],
    seriesSpark1: [{
      data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54]
    }],
    seriesSpark2: [{
      data: [47, 45, 74, 14, 56, 74, 14, 11, 7, 39, 82]
    }],
    seriesSpark3: [{
      data: [12, 14, 2, 47, 42, 15, 47, 75, 65, 19, 14]
    }],
    seriesSpark4: [{
      data: [15, 75, 47, 65, 14, 2, 41, 54, 4, 27, 15]
    }],
    chartOptionsTopSpark1: {
      chart: {
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: true
        },
      },
      colors: ['#CDD3DA'],
      stroke: {
        curve: 'straight'
      },
      fill: {
        opacity: 0.3,
      },
      xaxis: {
        crosshairs: {
          width: 1
        },
      },
      yaxis: {
        min: 0
      },
      title: {
        text: '215',
        offsetX: 0,
        style: {
          fontSize: '24px',
          cssClass: 'apexcharts-yaxis-title',
          fontWeight: 300
        }
      },
      subtitle: {
        text: t('sent'),
        offsetX: 0,
        style: {
          fontSize: '14px',
          cssClass: 'apexcharts-yaxis-title'
        }
      }
    },
    chartOptionsTopSpark2: {
      chart: {
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: true
        },
      },
      colors: ['#4FC3B3'],
      stroke: {
        curve: 'straight'
      },
      fill: {
        opacity: 0.3
      },
      xaxis: {
        crosshairs: {
          width: 1
        },
      },
      yaxis: {
        min: 0
      },
      title: {
        text: '155',
        offsetX: 0,
        style: {
          fontSize: '24px',
          cssClass: 'apexcharts-yaxis-title',
          fontWeight: 300
        }
      },
      subtitle: {
        text: t('open'),
        offsetX: 0,
        style: {
          fontSize: '14px',
          cssClass: 'apexcharts-yaxis-title'
        }
      }
    },
    chartOptionsTopSpark3: {
      chart: {
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: true
        },
      },
      stroke: {
        curve: 'straight'
      },
      fill: {
        opacity: 0.3,
      },
      xaxis: {
        crosshairs: {
          width: 1
        },
      },
      yaxis: {
        min: 0
      },
      title: {
        text: '90',
        offsetX: 0,
        style: {
          fontSize: '24px',
          cssClass: 'apexcharts-yaxis-title',
          fontWeight: 300
        }
      },
      subtitle: {
        text: t('answered-surveys'),
        offsetX: 0,
        style: {
          fontSize: '14px',
          cssClass: 'apexcharts-yaxis-title'
        }
      }
    },
    chartOptionsSparkLine: {
      chart: {
        toolbar: {
          show: false,
        },
        height: 35,
        sparkline: {
          enabled: true
        }
      },
      plotOptions: {
        bar: {
          columnWidth: '80%'
        }
      },
      xaxis: {
        crosshairs: {
          width: 1
        },
      },

    }
  }

    return (
      <div id="sparklines">
        <div className="row">
          <div className="col-md-4">
            <div id="spark1">
              <Chart className={'my-chart'} options={optionsChart.chartOptionsTopSpark1} series={optionsChart.seriesTopSpark1} type="area" height="160" />
            </div>
          </div>
          <div className="col-md-4">
            <div id="spark2">
              <Chart className={'my-chart'} options={optionsChart.chartOptionsTopSpark2} series={optionsChart.seriesTopSpark2} type="area" height="160" />
            </div>
          </div>
          <div className="col-md-4">
            <div id="spark3">
              <Chart className={'my-chart'} options={optionsChart.chartOptionsTopSpark3} series={optionsChart.seriesTopSpark3} type="area" height="160" />
            </div>
          </div>
        </div>
      </div>
    );
}

export default SparklinesCharts;
