import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import {Card,CardBody,CardHeader} from 'shards-react';

const colorScale = {
  ranges: [
    {
      from: 100,
      to: 100,
      name: "Excelente",
      color: "#4FC3B3"
    },
    {
      from: 90,
      to: 99,
      name: "",
      color: "#67C190"
    },
    {
      from: 80,
      to: 89,
      name: "Bueno",
      color: "#E9C563"
    },
    {
      from: 70,
      to: 79,
      name: "",
      color: "#DDB34A"
    },
    {
      from: 60,
      to: 69,
      name: "",
      color: "#E5A865"
    },
    {
      from: 50,
      to: 59,
      name: "",
      color: "#E0A368"
    },
    {
      from: 40,
      to: 49,
      name: "",
      color: "#DB8916"
    },
    {
      from: 30,
      to: 39,
      name: "",
      color: "#D86619"
    },
    {
      from: 20,
      to: 29,
      name: "",
      color: "#D6411A"
    },
    {
      from: -100,
      to: 0,
      name: "otro",
      color: "#9E2320"
    },
    {
      from: 1,
      to: 19,
      name: "Malo",
      color: "#C22821"
    },
    {
      name: " ",
      from: "",
      to: "",
      color: "#FFFFFF"
    }
  ]
};

const datos = {
  data: {
    MapaCalor: [
      {
        hora: "23",
        data: [
          {
            dia_nombre: "Sun",
            dia: "0",
            hora: 23,
            nps: "89",
            cant_v2: "3",
            cant_v4: "1",
            cant_v3: "2",
            cant_v1: "75",
            cant_votos: "81"
          },
          {
            dia_nombre: "Mon",
            dia: "1",
            hora: "23",
            nps: "100",
            cant_v2: "0",
            cant_v4: "0",
            cant_v3: "0",
            cant_v1: "48",
            cant_votos: "48"
          },
          {
            dia_nombre: "Tue",
            dia: "2",
            hora: "23",
            nps: "92",
            cant_v2: "2",
            cant_v4: "1",
            cant_v3: "0",
            cant_v1: "50",
            cant_votos: "53"
          },
          {
            dia_nombre: "Wed",
            dia: "3",
            hora: "23",
            nps: "72",
            cant_v2: "5",
            cant_v4: "2",
            cant_v3: "2",
            cant_v1: "37",
            cant_votos: "46"
          },
          {
            dia_nombre: "Thu",
            dia: "4",
            hora: "23",
            nps: "69",
            cant_v2: "8",
            cant_v4: "7",
            cant_v3: "0",
            cant_v1: "56",
            cant_votos: "71"
          },
          {
            dia_nombre: "Fri",
            dia: "5",
            hora: "23",
            nps: "73",
            cant_v2: "6",
            cant_v4: "5",
            cant_v3: "2",
            cant_v1: "62",
            cant_votos: "75"
          },
          {
            dia_nombre: "Sat",
            dia: "6",
            hora: "23",
            nps: "85",
            cant_v2: "2",
            cant_v4: "3",
            cant_v3: "0",
            cant_v1: "49",
            cant_votos: "54"
          }
        ]
      },
      {
        hora: "22",
        data: [
          {
            dia_nombre: "Sun",
            dia: "0",
            hora: "22",
            nps: "70",
            cant_v2: "9",
            cant_v4: "4",
            cant_v3: "2",
            cant_v1: "56",
            cant_votos: "71"
          },
          {
            dia_nombre: "Mon",
            dia: "1",
            hora: "22",
            nps: "87",
            cant_v2: "5",
            cant_v4: "1",
            cant_v3: "0",
            cant_v1: "49",
            cant_votos: "55"
          },
          {
            dia_nombre: "Tue",
            dia: "2",
            hora: "22",
            nps: "69",
            cant_v2: "6",
            cant_v4: "3",
            cant_v3: "2",
            cant_v1: "41",
            cant_votos: "52"
          },
          {
            dia_nombre: "Wed",
            dia: "3",
            hora: "22",
            nps: "81",
            cant_v2: "3",
            cant_v4: "3",
            cant_v3: "0",
            cant_v1: "41",
            cant_votos: "47"
          },
          {
            dia_nombre: "Thu",
            dia: "4",
            hora: "22",
            nps: "90",
            cant_v2: "5",
            cant_v4: "0",
            cant_v3: "0",
            cant_v1: "47",
            cant_votos: "52"
          },
          {
            dia_nombre: "Fri",
            dia: "5",
            hora: "22",
            nps: "82",
            cant_v2: "6",
            cant_v4: "2",
            cant_v3: "1",
            cant_v1: "59",
            cant_votos: "68"
          },
          {
            dia_nombre: "Sat",
            dia: "6",
            hora: "22",
            nps: "76",
            cant_v2: "2",
            cant_v4: "4",
            cant_v3: "1",
            cant_v1: "43",
            cant_votos: "50"
          }
        ]
      },
      {
        hora: "21",
        data: [
          {
            dia_nombre: "Sun",
            dia: "0",
            hora: "21",
            nps: "85",
            cant_v2: "12",
            cant_v4: "1",
            cant_v3: "2",
            cant_v1: "103",
            cant_votos: "118"
          },
          {
            dia_nombre: "Mon",
            dia: "1",
            hora: "21",
            nps: "79",
            cant_v2: "7",
            cant_v4: "6",
            cant_v3: "0",
            cant_v1: "77",
            cant_votos: "90"
          },
          {
            dia_nombre: "Tue",
            dia: "2",
            hora: "21",
            nps: "86",
            cant_v2: "3",
            cant_v4: "3",
            cant_v3: "1",
            cant_v1: "71",
            cant_votos: "78"
          },
          {
            dia_nombre: "Wed",
            dia: "3",
            hora: "21",
            nps: "89",
            cant_v2: "2",
            cant_v4: "2",
            cant_v3: "2",
            cant_v1: "82",
            cant_votos: "88"
          },
          {
            dia_nombre: "Thu",
            dia: "4",
            hora: "21",
            nps: "78",
            cant_v2: "6",
            cant_v4: "6",
            cant_v3: "0",
            cant_v1: "70",
            cant_votos: "82"
          },
          {
            dia_nombre: "Fri",
            dia: "5",
            hora: "21",
            nps: "87",
            cant_v2: "5",
            cant_v4: "2",
            cant_v3: "1",
            cant_v1: "74",
            cant_votos: "82"
          },
          {
            dia_nombre: "Sat",
            dia: "6",
            hora: "21",
            nps: "82",
            cant_v2: "6",
            cant_v4: "1",
            cant_v3: "1",
            cant_v1: "48",
            cant_votos: "56"
          }
        ]
      },
      {
        hora: "20",
        data: [
          {
            dia_nombre: "Sun",
            dia: "0",
            hora: "20",
            nps: "75",
            cant_v2: "4",
            cant_v4: "8",
            cant_v3: "5",
            cant_v1: "105",
            cant_votos: "122"
          },
          {
            dia_nombre: "Mon",
            dia: "1",
            hora: "20",
            nps: "84",
            cant_v2: "5",
            cant_v4: "3",
            cant_v3: "3",
            cant_v1: "96",
            cant_votos: "107"
          },
          {
            dia_nombre: "Tue",
            dia: "2",
            hora: "20",
            nps: "83",
            cant_v2: "6",
            cant_v4: "2",
            cant_v3: "2",
            cant_v1: "74",
            cant_votos: "84"
          },
          {
            dia_nombre: "Wed",
            dia: "3",
            hora: "20",
            nps: "83",
            cant_v2: "5",
            cant_v4: "5",
            cant_v3: "2",
            cant_v1: "97",
            cant_votos: "109"
          },
          {
            dia_nombre: "Thu",
            dia: "4",
            hora: "20",
            nps: "94",
            cant_v2: "7",
            cant_v4: "0",
            cant_v3: "0",
            cant_v1: "103",
            cant_votos: "110"
          },
          {
            dia_nombre: "Fri",
            dia: "5",
            hora: "20",
            nps: "82",
            cant_v2: "5",
            cant_v4: "5",
            cant_v3: "3",
            cant_v1: "105",
            cant_votos: "118"
          },
          {
            dia_nombre: "Sat",
            dia: "6",
            hora: "20",
            nps: "86",
            cant_v2: "6",
            cant_v4: "3",
            cant_v3: "0",
            cant_v1: "75",
            cant_votos: "84"
          }
        ]
      },
      {
        hora: "19",
        data: [
          {
            dia_nombre: "Sun",
            dia: "0",
            hora: "19",
            nps: "69",
            cant_v2: "14",
            cant_v4: "6",
            cant_v3: "7",
            cant_v1: "100",
            cant_votos: "127"
          },
          {
            dia_nombre: "Mon",
            dia: "1",
            hora: "19",
            nps: "80",
            cant_v2: "6",
            cant_v4: "3",
            cant_v3: "3",
            cant_v1: "77",
            cant_votos: "89"
          },
          {
            dia_nombre: "Tue",
            dia: "2",
            hora: "19",
            nps: "91",
            cant_v2: "2",
            cant_v4: "2",
            cant_v3: "1",
            cant_v1: "84",
            cant_votos: "89"
          },
          {
            dia_nombre: "Wed",
            dia: "3",
            hora: "19",
            nps: "82",
            cant_v2: "11",
            cant_v4: "3",
            cant_v3: "2",
            cant_v1: "103",
            cant_votos: "119"
          },
          {
            dia_nombre: "Thu",
            dia: "4",
            hora: "19",
            nps: "88",
            cant_v2: "5",
            cant_v4: "4",
            cant_v3: "0",
            cant_v1: "98",
            cant_votos: "107"
          },
          {
            dia_nombre: "Fri",
            dia: "5",
            hora: "19",
            nps: "84",
            cant_v2: "11",
            cant_v4: "3",
            cant_v3: "0",
            cant_v1: "92",
            cant_votos: "106"
          },
          {
            dia_nombre: "Sat",
            dia: "6",
            hora: "19",
            nps: "89",
            cant_v2: "5",
            cant_v4: "3",
            cant_v3: "0",
            cant_v1: "88",
            cant_votos: "96"
          }
        ]
      },
      {
        hora: "18",
        data: [
          {
            dia_nombre: "Sun",
            dia: "0",
            hora: "18",
            nps: "85",
            cant_v2: "8",
            cant_v4: "3",
            cant_v3: "0",
            cant_v1: "81",
            cant_votos: "92"
          },
          {
            dia_nombre: "Mon",
            dia: "1",
            hora: "18",
            nps: "71",
            cant_v2: "9",
            cant_v4: "8",
            cant_v3: "2",
            cant_v1: "81",
            cant_votos: "100"
          },
          {
            dia_nombre: "Tue",
            dia: "2",
            hora: "18",
            nps: "83",
            cant_v2: "11",
            cant_v4: "3",
            cant_v3: "1",
            cant_v1: "100",
            cant_votos: "115"
          },
          {
            dia_nombre: "Wed",
            dia: "3",
            hora: "18",
            nps: "79",
            cant_v2: "12",
            cant_v4: "1",
            cant_v3: "3",
            cant_v1: "80",
            cant_votos: "96"
          },
          {
            dia_nombre: "Thu",
            dia: "4",
            hora: "18",
            nps: "75",
            cant_v2: "11",
            cant_v4: "7",
            cant_v3: "2",
            cant_v1: "95",
            cant_votos: "115"
          },
          {
            dia_nombre: "Fri",
            dia: "5",
            hora: "18",
            nps: "80",
            cant_v2: "6",
            cant_v4: "7",
            cant_v3: "1",
            cant_v1: "95",
            cant_votos: "109"
          },
          {
            dia_nombre: "Sat",
            dia: "6",
            hora: "18",
            nps: "84",
            cant_v2: "7",
            cant_v4: "3",
            cant_v3: "1",
            cant_v1: "83",
            cant_votos: "94"
          }
        ]
      },
      {
        hora: "17",
        data: [
          {
            dia_nombre: "Sun",
            dia: "0",
            hora: "17",
            nps: "80",
            cant_v2: "8",
            cant_v4: "5",
            cant_v3: "1",
            cant_v1: "86",
            cant_votos: "100"
          },
          {
            dia_nombre: "Mon",
            dia: "1",
            hora: "17",
            nps: "83",
            cant_v2: "7",
            cant_v4: "3",
            cant_v3: "2",
            cant_v1: "90",
            cant_votos: "102"
          },
          {
            dia_nombre: "Tue",
            dia: "2",
            hora: "17",
            nps: "82",
            cant_v2: "11",
            cant_v4: "6",
            cant_v3: "0",
            cant_v1: "109",
            cant_votos: "126"
          },
          {
            dia_nombre: "Wed",
            dia: "3",
            hora: "17",
            nps: "90",
            cant_v2: "3",
            cant_v4: "4",
            cant_v3: "0",
            cant_v1: "100",
            cant_votos: "107"
          },
          {
            dia_nombre: "Thu",
            dia: "4",
            hora: "17",
            nps: "87",
            cant_v2: "5",
            cant_v4: "4",
            cant_v3: "1",
            cant_v1: "106",
            cant_votos: "116"
          },
          {
            dia_nombre: "Fri",
            dia: "5",
            hora: "17",
            nps: "77",
            cant_v2: "13",
            cant_v4: "7",
            cant_v3: "1",
            cant_v1: "104",
            cant_votos: "125"
          },
          {
            dia_nombre: "Sat",
            dia: "6",
            hora: "17",
            nps: "90",
            cant_v2: "3",
            cant_v4: "1",
            cant_v3: "2",
            cant_v1: "84",
            cant_votos: "90"
          }
        ]
      },

      {
        hora: "16",
        data: [
          {
            dia_nombre: "Sun",
            dia: "0",
            hora: "16",
            nps: "80",
            cant_v2: "4",
            cant_v4: "4",
            cant_v3: "1",
            cant_v1: "61",
            cant_votos: "70"
          },
          {
            dia_nombre: "Mon",
            dia: "1",
            hora: "16",
            nps: "83",
            cant_v2: "10",
            cant_v4: "3",
            cant_v3: "1",
            cant_v1: "94",
            cant_votos: "108"
          },
          {
            dia_nombre: "Tue",
            dia: "2",
            hora: "16",
            nps: "76",
            cant_v2: "9",
            cant_v4: "6",
            cant_v3: "3",
            cant_v1: "95",
            cant_votos: "113"
          },
          {
            dia_nombre: "Wed",
            dia: "3",
            hora: "16",
            nps: "88",
            cant_v2: "4",
            cant_v4: "3",
            cant_v3: "2",
            cant_v1: "106",
            cant_votos: "115"
          },
          {
            dia_nombre: "Thu",
            dia: "4",
            hora: "16",
            nps: "92",
            cant_v2: "6",
            cant_v4: "2",
            cant_v3: "0",
            cant_v1: "118",
            cant_votos: "126"
          },
          {
            dia_nombre: "Fri",
            dia: "5",
            hora: "16",
            nps: "83",
            cant_v2: "9",
            cant_v4: "1",
            cant_v3: "4",
            cant_v1: "99",
            cant_votos: "113"
          },
          {
            dia_nombre: "Sat",
            dia: "6",
            hora: "16",
            nps: "87",
            cant_v2: "5",
            cant_v4: "3",
            cant_v3: "0",
            cant_v1: "79",
            cant_votos: "87"
          }
        ]
      },

      {
        hora: "15",
        data: [
          {
            dia_nombre: "Sun",
            dia: "0",
            hora: "15",
            nps: "78",
            cant_v2: "4",
            cant_v4: "6",
            cant_v3: "1",
            cant_v1: "69",
            cant_votos: "80"
          },
          {
            dia_nombre: "Mon",
            dia: "1",
            hora: "15",
            nps: "74",
            cant_v2: "16",
            cant_v4: "2",
            cant_v3: "2",
            cant_v1: "73",
            cant_votos: "93"
          },
          {
            dia_nombre: "Tue",
            dia: "2",
            hora: "15",
            nps: "88",
            cant_v2: "9",
            cant_v4: "1",
            cant_v3: "0",
            cant_v1: "82",
            cant_votos: "92"
          },
          {
            dia_nombre: "Wed",
            dia: "3",
            hora: "15",
            nps: "82",
            cant_v2: "9",
            cant_v4: "4",
            cant_v3: "0",
            cant_v1: "84",
            cant_votos: "97"
          },
          {
            dia_nombre: "Thu",
            dia: "4",
            hora: "15",
            nps: "88",
            cant_v2: "5",
            cant_v4: "2",
            cant_v3: "1",
            cant_v1: "83",
            cant_votos: "91"
          },
          {
            dia_nombre: "Fri",
            dia: "5",
            hora: "15",
            nps: "81",
            cant_v2: "9",
            cant_v4: "2",
            cant_v3: "3",
            cant_v1: "86",
            cant_votos: "100"
          },
          {
            dia_nombre: "Sat",
            dia: "6",
            hora: "15",
            nps: "70",
            cant_v2: "9",
            cant_v4: "5",
            cant_v3: "5",
            cant_v1: "78",
            cant_votos: "97"
          }
        ]
      },

      {
        hora: "14",
        data: [
          {
            dia_nombre: "Sun",
            dia: "0",
            hora: "14",
            nps: "87",
            cant_v2: "4",
            cant_v4: "2",
            cant_v3: "1",
            cant_v1: "72",
            cant_votos: "79"
          },
          {
            dia_nombre: "Mon",
            dia: "1",
            hora: "14",
            nps: "89",
            cant_v2: "5",
            cant_v4: "3",
            cant_v3: "0",
            cant_v1: "90",
            cant_votos: "98"
          },
          {
            dia_nombre: "Tue",
            dia: "2",
            hora: "14",
            nps: "88",
            cant_v2: "4",
            cant_v4: "2",
            cant_v3: "2",
            cant_v1: "96",
            cant_votos: "104"
          },
          {
            dia_nombre: "Wed",
            dia: "3",
            hora: "14",
            nps: "80",
            cant_v2: "11",
            cant_v4: "3",
            cant_v3: "1",
            cant_v1: "78",
            cant_votos: "93"
          },
          {
            dia_nombre: "Thu",
            dia: "4",
            hora: "14",
            nps: "82",
            cant_v2: "13",
            cant_v4: "4",
            cant_v3: "1",
            cant_v1: "107",
            cant_votos: "125"
          },
          {
            dia_nombre: "Fri",
            dia: "5",
            hora: "14",
            nps: "80",
            cant_v2: "10",
            cant_v4: "6",
            cant_v3: "1",
            cant_v1: "105",
            cant_votos: "122"
          },
          {
            dia_nombre: "Sat",
            dia: "6",
            hora: "14",
            nps: "77",
            cant_v2: "8",
            cant_v4: "4",
            cant_v3: "3",
            cant_v1: "79",
            cant_votos: "94"
          }
        ]
      },

      {
        hora: "13",
        data: [
          {
            dia_nombre: "Sun",
            dia: "0",
            hora: "13",
            nps: "76",
            cant_v2: "13",
            cant_v4: "0",
            cant_v3: "2",
            cant_v1: "55",
            cant_votos: "70"
          },
          {
            dia_nombre: "Mon",
            dia: "1",
            hora: "13",
            nps: "82",
            cant_v2: "10",
            cant_v4: "4",
            cant_v3: "2",
            cant_v1: "105",
            cant_votos: "121"
          },
          {
            dia_nombre: "Tue",
            dia: "2",
            hora: "13",
            nps: "75",
            cant_v2: "9",
            cant_v4: "4",
            cant_v3: "4",
            cant_v1: "82",
            cant_votos: "99"
          },
          {
            dia_nombre: "Wed",
            dia: "3",
            hora: "13",
            nps: "79",
            cant_v2: "12",
            cant_v4: "3",
            cant_v3: "2",
            cant_v1: "88",
            cant_votos: "105"
          },
          {
            dia_nombre: "Thu",
            dia: "4",
            hora: "13",
            nps: "78",
            cant_v2: "11",
            cant_v4: "7",
            cant_v3: "1",
            cant_v1: "104",
            cant_votos: "123"
          },
          {
            dia_nombre: "Fri",
            dia: "5",
            hora: "13",
            nps: "84",
            cant_v2: "6",
            cant_v4: "1",
            cant_v3: "5",
            cant_v1: "102",
            cant_votos: "114"
          },
          {
            dia_nombre: "Sat",
            dia: "6",
            hora: "13",
            nps: "90",
            cant_v2: "4",
            cant_v4: "1",
            cant_v3: "2",
            cant_v1: "94",
            cant_votos: "101"
          }
        ]
      },

      {
        hora: "12",
        data: [
          {
            dia_nombre: "Sun",
            dia: "0",
            hora: "12",
            nps: "81",
            cant_v2: "6",
            cant_v4: "4",
            cant_v3: "0",
            cant_v1: "62",
            cant_votos: "72"
          },
          {
            dia_nombre: "Mon",
            dia: "1",
            hora: "12",
            nps: "82",
            cant_v2: "8",
            cant_v4: "4",
            cant_v3: "2",
            cant_v1: "96",
            cant_votos: "110"
          },
          {
            dia_nombre: "Tue",
            dia: "2",
            hora: "12",
            nps: "90",
            cant_v2: "4",
            cant_v4: "1",
            cant_v3: "1",
            cant_v1: "78",
            cant_votos: "84"
          },
          {
            dia_nombre: "Wed",
            dia: "3",
            hora: "12",
            nps: "85",
            cant_v2: "7",
            cant_v4: "5",
            cant_v3: "0",
            cant_v1: "103",
            cant_votos: "115"
          },
          {
            dia_nombre: "Thu",
            dia: "4",
            hora: "12",
            nps: "81",
            cant_v2: "12",
            cant_v4: "1",
            cant_v3: "3",
            cant_v1: "90",
            cant_votos: "106"
          },
          {
            dia_nombre: "Fri",
            dia: "5",
            hora: "12",
            nps: "84",
            cant_v2: "8",
            cant_v4: "2",
            cant_v3: "3",
            cant_v1: "102",
            cant_votos: "115"
          },
          {
            dia_nombre: "Sat",
            dia: "6",
            hora: "12",
            nps: "88",
            cant_v2: "6",
            cant_v4: "0",
            cant_v3: "2",
            cant_v1: "78",
            cant_votos: "86"
          }
        ]
      },

      {
        hora: "11",
        data: [
          {
            dia_nombre: "Sun",
            dia: "0",
            hora: "11",
            nps: "81",
            cant_v2: "2",
            cant_v4: "3",
            cant_v3: "2",
            cant_v1: "55",
            cant_votos: "62"
          },
          {
            dia_nombre: "Mon",
            dia: "1",
            hora: "11",
            nps: "77",
            cant_v2: "10",
            cant_v4: "3",
            cant_v3: "6",
            cant_v1: "102",
            cant_votos: "121"
          },
          {
            dia_nombre: "Tue",
            dia: "2",
            hora: "11",
            nps: "85",
            cant_v2: "11",
            cant_v4: "2",
            cant_v3: "1",
            cant_v1: "96",
            cant_votos: "110"
          },
          {
            dia_nombre: "Wed",
            dia: "3",
            hora: "11",
            nps: "84",
            cant_v2: "12",
            cant_v4: "3",
            cant_v3: "1",
            cant_v1: "110",
            cant_votos: "126"
          },
          {
            dia_nombre: "Thu",
            dia: "4",
            hora: "11",
            nps: "78",
            cant_v2: "8",
            cant_v4: "7",
            cant_v3: "4",
            cant_v1: "119",
            cant_votos: "138"
          },
          {
            dia_nombre: "Fri",
            dia: "5",
            hora: "11",
            nps: "86",
            cant_v2: "6",
            cant_v4: "3",
            cant_v3: "2",
            cant_v1: "102",
            cant_votos: "113"
          },
          {
            dia_nombre: "Sat",
            dia: "6",
            hora: "11",
            nps: "81",
            cant_v2: "6",
            cant_v4: "3",
            cant_v3: "2",
            cant_v1: "73",
            cant_votos: "84"
          }
        ]
      },

      {
        hora: "10",
        data: [
          {
            dia_nombre: "Sun",
            dia: "0",
            hora: "10",
            nps: "81",
            cant_v2: "4",
            cant_v4: "4",
            cant_v3: "1",
            cant_v1: "65",
            cant_votos: "74"
          },
          {
            dia_nombre: "Mon",
            dia: "1",
            hora: "10",
            nps: "88",
            cant_v2: "6",
            cant_v4: "3",
            cant_v3: "1",
            cant_v1: "105",
            cant_votos: "115"
          },
          {
            dia_nombre: "Tue",
            dia: "2",
            hora: "10",
            nps: "82",
            cant_v2: "8",
            cant_v4: "1",
            cant_v3: "3",
            cant_v1: "77",
            cant_votos: "89"
          },
          {
            dia_nombre: "Wed",
            dia: "3",
            hora: "10",
            nps: "82",
            cant_v2: "12",
            cant_v4: "4",
            cant_v3: "1",
            cant_v1: "105",
            cant_votos: "122"
          },
          {
            dia_nombre: "Thu",
            dia: "4",
            hora: "10",
            nps: "82",
            cant_v2: "11",
            cant_v4: "7",
            cant_v3: "2",
            cant_v1: "138",
            cant_votos: "158"
          },
          {
            dia_nombre: "Fri",
            dia: "5",
            hora: "10",
            nps: "82",
            cant_v2: "7",
            cant_v4: "4",
            cant_v3: "3",
            cant_v1: "101",
            cant_votos: "115"
          },
          {
            dia_nombre: "Sat",
            dia: "6",
            hora: "10",
            nps: "90",
            cant_v2: "4",
            cant_v4: "0",
            cant_v3: "2",
            cant_v1: "71",
            cant_votos: "77"
          }
        ]
      },

      {
        hora: "09",
        data: [
          {
            dia_nombre: "Sun",
            dia: "0",
            hora: "09",
            nps: "79",
            cant_v2: "7",
            cant_v4: "3",
            cant_v3: "0",
            cant_v1: "53",
            cant_votos: "63"
          },
          {
            dia_nombre: "Mon",
            dia: "1",
            hora: "09",
            nps: "75",
            cant_v2: "8",
            cant_v4: "6",
            cant_v3: "4",
            cant_v1: "96",
            cant_votos: "114"
          },
          {
            dia_nombre: "Tue",
            dia: "2",
            hora: "09",
            nps: "80",
            cant_v2: "6",
            cant_v4: "2",
            cant_v3: "5",
            cant_v1: "88",
            cant_votos: "101"
          },
          {
            dia_nombre: "Wed",
            dia: "3",
            hora: "09",
            nps: "86",
            cant_v2: "10",
            cant_v4: "1",
            cant_v3: "1",
            cant_v1: "91",
            cant_votos: "103"
          },
          {
            dia_nombre: "Thu",
            dia: "4",
            hora: "09",
            nps: "83",
            cant_v2: "7",
            cant_v4: "5",
            cant_v3: "1",
            cant_v1: "98",
            cant_votos: "111"
          },
          {
            dia_nombre: "Fri",
            dia: "5",
            hora: "09",
            nps: "77",
            cant_v2: "7",
            cant_v4: "8",
            cant_v3: "2",
            cant_v1: "101",
            cant_votos: "118"
          },
          {
            dia_nombre: "Sat",
            dia: "6",
            hora: "09",
            nps: "91",
            cant_v2: "5",
            cant_v4: "1",
            cant_v3: "0",
            cant_v1: "69",
            cant_votos: "75"
          }
        ]
      },

      {
        hora: "08",
        data: [
          {
            dia_nombre: "Sun",
            dia: "0",
            hora: "08",
            nps: "79",
            cant_v2: "5",
            cant_v4: "4",
            cant_v3: "4",
            cant_v1: "86",
            cant_votos: "99"
          },
          {
            dia_nombre: "Mon",
            dia: "1",
            hora: "08",
            nps: "87",
            cant_v2: "4",
            cant_v4: "2",
            cant_v3: "1",
            cant_v1: "68",
            cant_votos: "75"
          },
          {
            dia_nombre: "Tue",
            dia: "2",
            hora: "08",
            nps: "84",
            cant_v2: "4",
            cant_v4: "4",
            cant_v3: "1",
            cant_v1: "80",
            cant_votos: "89"
          },
          {
            dia_nombre: "Wed",
            dia: "3",
            hora: "08",
            nps: "81",
            cant_v2: "7",
            cant_v4: "4",
            cant_v3: "1",
            cant_v1: "78",
            cant_votos: "90"
          },
          {
            dia_nombre: "Thu",
            dia: "4",
            hora: "08",
            nps: "89",
            cant_v2: "6",
            cant_v4: "2",
            cant_v3: "0",
            cant_v1: "86",
            cant_votos: "94"
          },
          {
            dia_nombre: "Fri",
            dia: "5",
            hora: "08",
            nps: "84",
            cant_v2: "6",
            cant_v4: "4",
            cant_v3: "1",
            cant_v1: "89",
            cant_votos: "100"
          },
          {
            dia_nombre: "Sat",
            dia: "6",
            hora: "08",
            nps: "87",
            cant_v2: "3",
            cant_v4: "1",
            cant_v3: "2",
            cant_v1: "64",
            cant_votos: "70"
          }
        ]
      },

      {
        hora: "07",
        data: [
          {
            dia_nombre: "Sun",
            dia: "0",
            hora: "07",
            nps: "99",
            cant_v2: "1",
            cant_v4: "0",
            cant_v3: "0",
            cant_v1: "67",
            cant_votos: "68"
          },
          {
            dia_nombre: "Mon",
            dia: "1",
            hora: "07",
            nps: "91",
            cant_v2: "4",
            cant_v4: "1",
            cant_v3: "1",
            cant_v1: "83",
            cant_votos: "89"
          },
          {
            dia_nombre: "Tue",
            dia: "2",
            hora: "07",
            nps: "92",
            cant_v2: "0",
            cant_v4: "3",
            cant_v3: "0",
            cant_v1: "76",
            cant_votos: "79"
          },
          {
            dia_nombre: "Wed",
            dia: "3",
            hora: "07",
            nps: "90",
            cant_v2: "5",
            cant_v4: "2",
            cant_v3: "0",
            cant_v1: "80",
            cant_votos: "87"
          },
          {
            dia_nombre: "Thu",
            dia: "4",
            hora: "07",
            nps: "94",
            cant_v2: "3",
            cant_v4: "1",
            cant_v3: "0",
            cant_v1: "75",
            cant_votos: "79"
          },
          {
            dia_nombre: "Fri",
            dia: "5",
            hora: "07",
            nps: "91",
            cant_v2: "3",
            cant_v4: "3",
            cant_v3: "0",
            cant_v1: "93",
            cant_votos: "99"
          },
          {
            dia_nombre: "Sat",
            dia: "6",
            hora: "07",
            nps: "86",
            cant_v2: "2",
            cant_v4: "1",
            cant_v3: "1",
            cant_v1: "38",
            cant_votos: "42"
          }
        ]
      },

      {
        hora: "06",
        data: [
          {
            dia_nombre: "Sun",
            dia: "0",
            hora: "06",
            nps: "84",
            cant_v2: "4",
            cant_v4: "2",
            cant_v3: "1",
            cant_v1: "55",
            cant_votos: "62"
          },
          {
            dia_nombre: "Mon",
            dia: "1",
            hora: "06",
            nps: "78",
            cant_v2: "7",
            cant_v4: "3",
            cant_v3: "0",
            cant_v1: "50",
            cant_votos: "60"
          },
          {
            dia_nombre: "Tue",
            dia: "2",
            hora: "06",
            nps: "88",
            cant_v2: "0",
            cant_v4: "2",
            cant_v3: "1",
            cant_v1: "49",
            cant_votos: "52"
          },
          {
            dia_nombre: "Wed",
            dia: "3",
            hora: "06",
            nps: "86",
            cant_v2: "4",
            cant_v4: "2",
            cant_v3: "1",
            cant_v1: "65",
            cant_votos: "72"
          },
          {
            dia_nombre: "Thu",
            dia: "4",
            hora: "06",
            nps: "83",
            cant_v2: "4",
            cant_v4: "2",
            cant_v3: "0",
            cant_v1: "40",
            cant_votos: "46"
          },
          {
            dia_nombre: "Fri",
            dia: "5",
            hora: "06",
            nps: "82",
            cant_v2: "5",
            cant_v4: "1",
            cant_v3: "2",
            cant_v1: "54",
            cant_votos: "62"
          },
          {
            dia_nombre: "Sat",
            dia: "6",
            hora: "06",
            nps: "77",
            cant_v2: "3",
            cant_v4: "4",
            cant_v3: "1",
            cant_v1: "48",
            cant_votos: "56"
          }
        ]
      },

      {
        hora: "05",
        data: [
          {
            dia_nombre: "Sun",
            dia: "0",
            hora: "05",
            nps: "86",
            cant_v2: "2",
            cant_v4: "1",
            cant_v3: "1",
            cant_v1: "39",
            cant_votos: "43"
          },
          {
            dia_nombre: "Mon",
            dia: "1",
            hora: "05",
            nps: "77",
            cant_v2: "1",
            cant_v4: "2",
            cant_v3: "1",
            cant_v1: "26",
            cant_votos: "30"
          },
          {
            dia_nombre: "Tue",
            dia: "2",
            hora: "05",
            nps: "96",
            cant_v2: "1",
            cant_v4: "0",
            cant_v3: "0",
            cant_v1: "22",
            cant_votos: "23"
          },
          {
            dia_nombre: "Wed",
            dia: "3",
            hora: "05",
            nps: "81",
            cant_v2: "2",
            cant_v4: "1",
            cant_v3: "1",
            cant_v1: "27",
            cant_votos: "31"
          },
          {
            dia_nombre: "Thu",
            dia: "4",
            hora: "05",
            nps: "88",
            cant_v2: "4",
            cant_v4: "0",
            cant_v3: "0",
            cant_v1: "29",
            cant_votos: "33"
          },
          {
            dia_nombre: "Fri",
            dia: "5",
            hora: "05",
            nps: "83",
            cant_v2: "2",
            cant_v4: "2",
            cant_v3: "0",
            cant_v1: "31",
            cant_votos: "35"
          },
          {
            dia_nombre: "Sat",
            dia: "6",
            hora: "05",
            nps: "86",
            cant_v2: "6",
            cant_v4: "0",
            cant_v3: "0",
            cant_v1: "36",
            cant_votos: "42"
          }
        ]
      },

      {
        hora: "04",
        data: [
          {
            dia_nombre: "Sun",
            dia: "0",
            hora: "04",
            nps: "83",
            cant_v2: "5",
            cant_v4: "2",
            cant_v3: "0",
            cant_v1: "46",
            cant_votos: "53"
          },
          {
            dia_nombre: "Mon",
            dia: "1",
            hora: "04",
            nps: "89",
            cant_v2: "2",
            cant_v4: "0",
            cant_v3: "0",
            cant_v1: "17",
            cant_votos: "19"
          },
          {
            dia_nombre: "Tue",
            dia: "2",
            hora: "04",
            nps: "82",
            cant_v2: "0",
            cant_v4: "1",
            cant_v3: "0",
            cant_v1: "10",
            cant_votos: "11"
          },
          {
            dia_nombre: "Wed",
            dia: "3",
            hora: "04",
            nps: "83",
            cant_v2: "1",
            cant_v4: "0",
            cant_v3: "1",
            cant_v1: "16",
            cant_votos: "18"
          },
          {
            dia_nombre: "Thu",
            dia: "4",
            hora: "04",
            nps: "100",
            cant_v2: "0",
            cant_v4: "0",
            cant_v3: "0",
            cant_v1: "11",
            cant_votos: "11"
          },
          {
            dia_nombre: "Fri",
            dia: "5",
            hora: "04",
            nps: "64",
            cant_v2: "2",
            cant_v4: "3",
            cant_v3: "0",
            cant_v1: "17",
            cant_votos: "22"
          },
          {
            dia_nombre: "Sat",
            dia: "6",
            hora: "04",
            nps: "79",
            cant_v2: "4",
            cant_v4: "1",
            cant_v3: "0",
            cant_v1: "24",
            cant_votos: "29"
          }
        ]
      },

      {
        hora: "03",
        data: [
          {
            dia_nombre: "Sun",
            dia: "0",
            hora: "03",
            nps: "87",
            cant_v2: "3",
            cant_v4: "2",
            cant_v3: "0",
            cant_v1: "50",
            cant_votos: "55"
          },
          {
            dia_nombre: "Mon",
            dia: "1",
            hora: "03",
            nps: "77",
            cant_v2: "1",
            cant_v4: "1",
            cant_v3: "1",
            cant_v1: "19",
            cant_votos: "22"
          },
          {
            dia_nombre: "Tue",
            dia: "2",
            hora: "03",
            nps: "62",
            cant_v2: "4",
            cant_v4: "2",
            cant_v3: "0",
            cant_v1: "15",
            cant_votos: "21"
          },
          {
            dia_nombre: "Wed",
            dia: "3",
            hora: "03",
            nps: "70",
            cant_v2: "0",
            cant_v4: "1",
            cant_v3: "2",
            cant_v1: "17",
            cant_votos: "20"
          },
          {
            dia_nombre: "Thu",
            dia: "4",
            hora: "03",
            nps: "91",
            cant_v2: "2",
            cant_v4: "0",
            cant_v3: "0",
            cant_v1: "21",
            cant_votos: "23"
          },
          {
            dia_nombre: "Fri",
            dia: "5",
            hora: "03",
            nps: "63",
            cant_v2: "3",
            cant_v4: "3",
            cant_v3: "1",
            cant_v1: "23",
            cant_votos: "30"
          },
          {
            dia_nombre: "Sat",
            dia: "6",
            hora: "03",
            nps: "62",
            cant_v2: "7",
            cant_v4: "5",
            cant_v3: "4",
            cant_v1: "50",
            cant_votos: "66"
          }
        ]
      },

      {
        hora: "02",
        data: [
          {
            dia_nombre: "Sun",
            dia: "0",
            hora: "02",
            nps: "79",
            cant_v2: "2",
            cant_v4: "2",
            cant_v3: "1",
            cant_v1: "34",
            cant_votos: "39"
          },
          {
            dia_nombre: "Mon",
            dia: "1",
            hora: "02",
            nps: "67",
            cant_v2: "3",
            cant_v4: "2",
            cant_v3: "0",
            cant_v1: "16",
            cant_votos: "21"
          },
          {
            dia_nombre: "Tue",
            dia: "2",
            hora: "02",
            nps: "81",
            cant_v2: "2",
            cant_v4: "2",
            cant_v3: "0",
            cant_v1: "27",
            cant_votos: "31"
          },
          {
            dia_nombre: "Wed",
            dia: "3",
            hora: "02",
            nps: "90",
            cant_v2: "2",
            cant_v4: "0",
            cant_v3: "1",
            cant_v1: "36",
            cant_votos: "39"
          },
          {
            dia_nombre: "Thu",
            dia: "4",
            hora: "02",
            nps: "96",
            cant_v2: "1",
            cant_v4: "0",
            cant_v3: "0",
            cant_v1: "25",
            cant_votos: "26"
          },
          {
            dia_nombre: "Fri",
            dia: "5",
            hora: "02",
            nps: "86",
            cant_v2: "1",
            cant_v4: "0",
            cant_v3: "1",
            cant_v1: "19",
            cant_votos: "21"
          },
          {
            dia_nombre: "Sat",
            dia: "6",
            hora: "02",
            nps: "73",
            cant_v2: "7",
            cant_v4: "2",
            cant_v3: "2",
            cant_v1: "44",
            cant_votos: "55"
          }
        ]
      },

      {
        hora: "01",
        data: [
          {
            dia_nombre: "Sun",
            dia: "0",
            hora: "01",
            nps: "81",
            cant_v2: "2",
            cant_v4: "5",
            cant_v3: "1",
            cant_v1: "65",
            cant_votos: "73"
          },
          {
            dia_nombre: "Mon",
            dia: "1",
            hora: "01",
            nps: "59",
            cant_v2: "4",
            cant_v4: "4",
            cant_v3: "2",
            cant_v1: "29",
            cant_votos: "39"
          },
          {
            dia_nombre: "Tue",
            dia: "2",
            hora: "01",
            nps: "67",
            cant_v2: "2",
            cant_v4: "2",
            cant_v3: "1",
            cant_v1: "19",
            cant_votos: "24"
          },
          {
            dia_nombre: "Wed",
            dia: "3",
            hora: "01",
            nps: "87",
            cant_v2: "1",
            cant_v4: "2",
            cant_v3: "0",
            cant_v1: "35",
            cant_votos: "38"
          },
          {
            dia_nombre: "Thu",
            dia: "4",
            hora: "01",
            nps: "62",
            cant_v2: "3",
            cant_v4: "6",
            cant_v3: "1",
            cant_v1: "35",
            cant_votos: "45"
          },
          {
            dia_nombre: "Fri",
            dia: "5",
            hora: "01",
            nps: "77",
            cant_v2: "5",
            cant_v4: "1",
            cant_v3: "2",
            cant_v1: "40",
            cant_votos: "48"
          },
          {
            dia_nombre: "Sat",
            dia: "6",
            hora: "01",
            nps: "76",
            cant_v2: "6",
            cant_v4: "2",
            cant_v3: "4",
            cant_v1: "62",
            cant_votos: "74"
          }
        ]
      },

      {
        hora: "00",
        data: [
          {
            dia_nombre: "Sun",
            dia: "0",
            hora: "00",
            nps: "91",
            cant_v2: "1",
            cant_v4: "0",
            cant_v3: "2",
            cant_v1: "53",
            cant_votos: "56"
          },
          {
            dia_nombre: "Mon",
            dia: "1",
            hora: "00",
            nps: "81",
            cant_v2: "3",
            cant_v4: "4",
            cant_v3: "1",
            cant_v1: "60",
            cant_votos: "68"
          },
          {
            dia_nombre: "Tue",
            dia: "2",
            hora: "00",
            nps: "75",
            cant_v2: "4",
            cant_v4: "2",
            cant_v3: "2",
            cant_v1: "40",
            cant_votos: "48"
          },
          {
            dia_nombre: "Wed",
            dia: "3",
            hora: "00",
            nps: "80",
            cant_v2: "4",
            cant_v4: "5",
            cant_v3: "1",
            cant_v1: "71",
            cant_votos: "81"
          },
          {
            dia_nombre: "Thu",
            dia: "4",
            hora: "00",
            nps: "72",
            cant_v2: "4",
            cant_v4: "6",
            cant_v3: "0",
            cant_v1: "47",
            cant_votos: "57"
          },
          {
            dia_nombre: "Fri",
            dia: "5",
            hora: "00",
            nps: "83",
            cant_v2: "6",
            cant_v4: "1",
            cant_v3: "1",
            cant_v1: "50",
            cant_votos: "58"
          },
          {
            dia_nombre: "Sat",
            dia: "6",
            hora: "00",
            nps: "76",
            cant_v2: "5",
            cant_v4: "2",
            cant_v3: "3",
            cant_v1: "52",
            cant_votos: "62"
          }
        ]
      }
    ]
  }
};

function tieneDia(hora, array) {
  var encuentra = false;
  array.map(cada_hora => {
    if (cada_hora.hora == hora) {
      encuentra = true;
    }
  });
  return encuentra;
}

function searchFor(array, hora, graficaMejorHorario = false) {
  var series = [];
  let dia_esperado = 0;
  let cont = 0;
  let dia_nom = "";

  if (tieneDia(hora, array)) {
    for (let i = 0; i < 7; i++) {
      dia_esperado = 0;
      while (dia_esperado != 1 && cont != 7) {
        if (cont == 0) {
          dia_nom = "Dom";
        }
        if (cont == 1) {
          dia_nom = "Lun";
        }
        if (cont == 2) {
          dia_nom = "Mar";
        }
        if (cont == 3) {
          dia_nom = "Mie";
        }
        if (cont == 4) {
          dia_nom = "Jue";
        }
        if (cont == 5) {
          dia_nom = "Vie";
        }
        if (cont == 6) {
          dia_nom = "Sab";
        }
        var diaBuscado = i.length == 1 ? "0" + i : i.toString();

        array.map(cada_hora => {
          if (cada_hora.hora == hora) {
            var dia =
              cada_hora.data[diaBuscado] != undefined
                ? cada_hora.data[diaBuscado].dia
                : cont;
            var nombre =
              cada_hora.data[diaBuscado] != undefined
                ? cada_hora.data[diaBuscado].dia_nombre
                : dia_nom;
            var nps =
              cada_hora.data[diaBuscado] != undefined
                ? cada_hora.data[diaBuscado].nps
                : null;
            if (dia == cont) {
              var x = nombre;
              if (nps != null) {
                var y = nps.toString();
              } else {
                var y = nps;
              }
              series.push({
                x: x,
                y: y
              });

              dia_esperado = 1;
            } else {
              series.push({
                x: nombre,
                y: null
              });
            }
          }
        });

        cont++;
      }
    }
  } else {
    for (let i = 0; i < 7; i++) {
      var diaBuscado = i.length == 1 ? "0" + i : i.toString();
      if (i == 0) {
        dia_nom = "Dom";
      }
      if (i == 1) {
        dia_nom = "Lun";
      }
      if (i == 2) {
        dia_nom = "Mar";
      }
      if (i == 3) {
        dia_nom = "Mie";
      }
      if (i == 4) {
        dia_nom = "Jue";
      }
      if (i == 5) {
        dia_nom = "Vie";
      }
      if (i == 6) {
        dia_nom = "Sab";
      }
      series.push({ x: dia_nom, y: null });
    }
  }

  return series;
}

const HeatChartRes = ({ params }) => {
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);
  const [data, setData] = useState(datos.data.MapaCalor);

  useEffect(() => {
    setOptions({
      plotOptions: {
        heatmap: {
          shadeIntensity: 0,
          colorScale
        }
      },
      chart: {
        height: 350,
        type: "heatmap"
      },
      dataLabels: {
        enabled: false
      },

      legend: {
        show: false
      }
    });
    setSeries([
      {
        name: "00",
        data: searchFor(data, 0)
      },
      {
        name: "01",
        data: searchFor(data, 1)
      },
      {
        name: "02",
        data: searchFor(data, 2)
      },
      {
        name: "03",
        data: searchFor(data, 3)
      },
      {
        name: "04",
        data: searchFor(data, 4)
      },
      {
        name: "05",
        data: searchFor(data, 5)
      },
      {
        name: "06",
        data: searchFor(data, 6)
      },
      {
        name: "07",
        data: searchFor(data, 7)
      },
      {
        name: "08",
        data: searchFor(data, 8)
      },
      {
        name: "09",
        data: searchFor(data, 9)
      },
      {
        name: "10",
        data: searchFor(data, 10)
      },
      {
        name: "11",
        data: searchFor(data, 11)
      },
      {
        name: "12",
        data: searchFor(data, 12)
      },
      {
        name: "13",
        data: searchFor(data, 13)
      },
      {
        name: "14",
        data: searchFor(data, 14)
      },
      {
        name: "15",
        data: searchFor(data, 15)
      },
      {
        name: "16",
        data: searchFor(data, 16)
      },
      {
        name: "17",
        data: searchFor(data, 17)
      },
      {
        name: "18",
        data: searchFor(data, 18)
      },
      {
        name: "19",
        data: searchFor(data, 19)
      },
      {
        name: "20",
        data: searchFor(data, 20)
      },
      {
        name: "21",
        data: searchFor(data, 21)
      },
      {
        name: "22",
        data: searchFor(data, 22)
      },
      {
        name: "23",
        data: searchFor(data, 23)
      }
    ]);
  }, [data]);

  return (
  <Card>
    <CardHeader>Mapa de Calor</CardHeader>
    <CardBody>
    <Chart options={options} series={series} type="heatmap" height="450px" />
    </CardBody>
  </Card>
  );
};
export default HeatChartRes;
