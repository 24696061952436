import React, { useState, useEffect } from 'react';
import { Col, Card, CardHeader, CardBody } from 'shards-react';
import { Table, Divider, Avatar } from 'antd';
import {ArrowUpOutlined,ArrowDownOutlined,ArrowRightOutlined}  from '@ant-design/icons';
import { useI18n } from '../../utils/i18n';
import resources from '../../translations/apex-charts.i18n';


function comparar_nps(record, pasado) {
  let nps = 0;
  for (let i = 0; i < pasado.length; i++) {
    if (record.sucursal == pasado[i].nombre_sucursal) {
      nps = record.nps - pasado[i].nps;
    }
  }
  return Math.trunc(nps);
}

function comparar(record, pasado) {
  let posicion = 0;
  let posicion_alternativa = 0;
  for (let i = 0; i < pasado.length; i++) {
    if (record.sucursal == pasado[i].nombre_sucursal) {
      posicion = i + 1;
    } else {
      posicion_alternativa = i + 1;
    }
  }

  if (posicion == 0) {
    posicion = posicion_alternativa;
  }
  return posicion;
}

function armar_array(actual) {
  var ranking = [];
  let cont = 1;
  for (let i = 0; i < actual.length; i++) {
    if (actual[i] != null) {
      ranking[i] = {
        ranking: i,
        sucursal: null,
        nps: 0
      };
    }
  }
  for (let i = 0; i < actual.length; i++) {
    if (actual[i] != null) {
      ranking[i].ranking = cont;
      ranking[i].sucursal = actual[i].nombre_sucursal;

      ranking[i].nps = Math.trunc(actual[i].nps);
    }
    cont++;
  }

  return ranking;
}

const printIcon = nps => {
  if (nps < 0) {
    return (
      <ArrowDownOutlined style={{display:'inline-flex'}}/>
    );
  } else if (nps > 0) {
    return (
      <ArrowUpOutlined style={{display:'inline-flex'}} />
    );
  }

  return (
    <ArrowRightOutlined style={{display:'inline-flex'}}/>
  );
};

const printNpsColor = nps => {
  if (nps < 0) {
    return (
      <h6 style={{ paddingLeft: '5px', fontSize: '12px', color: 'red' }}>

        {nps}
      </h6>
    );
  } else if (nps > 0) {
    return (
      <h6
        style={{ paddingLeft: '5px', fontSize: '12px', color: 'lightgreen' }}
      >

        {nps}
      </h6>
    );
  }

  return (
    <h6 style={{ paddingLeft: '5px', fontSize: '12px', color: 'grey' }}>

      {nps}
    </h6>
  );
};

const CompiladoTable = (next_props) => {
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState(undefined);
  const [rubro, setRubro] = useState(undefined);
  let posicion = undefined;

  const t = useI18n({ resources });


  useEffect(() => {
    setColumns([
      {
        title: 'Ranking',
        dataIndex: 'ranking',
        key: 'ranking',
        render: (colorcito, record) => (
          (posicion = comparar(record, next_props.pasado)),
          (
            <span style={{ fontSize: '12px', display: '-webkit-box' }}>
              {record.ranking}
              <Divider type="vertical" />
              {posicion == 0 ? (
                <Avatar style={{ backgroundColor: '#cacaca' }}>
                  <ArrowUpOutlined style={{display:'inline-flex'}}/>
                </Avatar>
              ) : record.ranking < posicion ? (
                <Avatar style={{ backgroundColor: 'lightgreen' }}>
                  <ArrowUpOutlined style={{display:'inline-flex'}}/>

                </Avatar>
              ) : record.ranking > posicion ? (
                <Avatar style={{ backgroundColor: 'red' }}>
                  <ArrowDownOutlined style={{display:'inline-flex'}}/>
                </Avatar>
              ) : (
                <Avatar style={{ backgroundColor: '#cacaca' }}>
                  <ArrowRightOutlined style={{display:'inline-flex'}} />
                </Avatar>
              )}
              <h6
                style={{
                  paddingLeft: '5px',
                  fontSize: '12px',
                  color: '#cacaca'
                }}
              >
                ({posicion})
              </h6>
            </span>
          )
        )
      },
      {
        title: t('branch-office'),
        dataIndex: 'sucursal',
        key: 'sucursal'
      },
      {
        title: 'NPS',
        dataIndex: 'nps',
        key: 'nps',
        render: (colorcito, dato) => {
          var nps = comparar_nps(dato, next_props.pasado);
          var color = 'green';
          return (
            <span style={{ display: '-webkit-box', fontSize: '15px' }}>
              {dato.nps}
              <Divider type="vertical" />
              {printIcon(nps)}
              {printNpsColor(nps)}
            </span>
          );
        }
      }
    ]);
  }, [next_props.pasado]);

  useEffect(() => {
    setData(armar_array(next_props.actual));
    setRubro(next_props.actual);
  }, [next_props.actual]);

  if (!rubro) return null;

  return (
    <Col lg="6" md="12" sm="12" className="mb-4">
      <Card className="h-100" style={{ minWidth: '300px' }}>
        <CardHeader>
          <h6 className="m-0">
            {rubro[0] != undefined
              ? rubro[0].nombre_rubro
              : 'Ranking'}
          </h6>
        </CardHeader>
        <CardBody
        >
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
          />
        </CardBody>
      </Card>
    </Col>
  );
}

export const RankingCard = () => {
  const [rankings,setRankings] = useState([]);

  const t = useI18n({ resources });

  const data={
    rankingPasado: [
      [
        {nombre_sucursal: `${t('branch-office')} 1`, nps:"64.4344", nombre_rubro: `${t('sector')} 1`},
        {nombre_sucursal: `${t('branch-office')} 2`, nps:"52.9092", nombre_rubro: `${t('sector')} 1`},
        {nombre_sucursal: `${t('branch-office')} 3`, nps:"42.5007", nombre_rubro: `${t('sector')} 1`},
        {nombre_sucursal: `${t('branch-office')} 4`, nps:"61.5576", nombre_rubro: `${t('sector')} 1`},
        {nombre_sucursal: `${t('branch-office')} 5`, nps:"70.3773", nombre_rubro: `${t('sector')} 1`},
        {nombre_sucursal: `${t('branch-office')} 6`, nps:"50.3027", nombre_rubro: `${t('sector')} 1`},
        {nombre_sucursal: `${t('branch-office')} 7`, nps:"47.5869", nombre_rubro: `${t('sector')} 1`}
      ],
      [
        {nombre_sucursal: `${t('branch-office')} 2`, nps:"51.1102", nombre_rubro: `${t('sector')} 2`},
        {nombre_sucursal: `${t('branch-office')} 3`, nps:"70.2768", nombre_rubro: `${t('sector')} 2`},
        {nombre_sucursal: `${t('branch-office')} 1`, nps:"62.2288", nombre_rubro: `${t('sector')} 2`},
        {nombre_sucursal: `${t('branch-office')} 6`, nps:"55.3269", nombre_rubro: `${t('sector')} 2`},
        {nombre_sucursal: `${t('branch-office')} 4`, nps:"51.9606", nombre_rubro: `${t('sector')} 2`},
        {nombre_sucursal: `${t('branch-office')} 7`, nps:"51.5753", nombre_rubro: `${t('sector')} 2`},
        {nombre_sucursal: `${t('branch-office')} 5`, nps:"-29.0009", nombre_rubro: `${t('sector')} 2`}
      ]
    ],
    rankingActual: [
      [
        {nombre_sucursal: `${t('branch-office')} 3`, nps:"72.3703", nombre_rubro: `${t('sector')} 1`},
        {nombre_sucursal: `${t('branch-office')} 4`, nps:"62.4662", nombre_rubro: `${t('sector')} 1`},
        {nombre_sucursal: `${t('branch-office')} 5`, nps:"60.4370", nombre_rubro: `${t('sector')} 1`},
        {nombre_sucursal: `${t('branch-office')} 7`, nps:"57.6561", nombre_rubro: `${t('sector')} 1`},
        {nombre_sucursal: `${t('branch-office')} 2`, nps:"53.2240", nombre_rubro: `${t('sector')} 1`},
        {nombre_sucursal: `${t('branch-office')} 6`, nps:"48.8081", nombre_rubro: `${t('sector')} 1`},
        {nombre_sucursal: `${t('branch-office')} 1`, nps:"44.4833", nombre_rubro: `${t('sector')} 1`},
      ],
      [
        {nombre_sucursal: `${t('branch-office')} 2`, nps:"76.6257", nombre_rubro: `${t('sector')} 2`},
        {nombre_sucursal: `${t('branch-office')} 3`, nps:"65.4007", nombre_rubro: `${t('sector')} 2`},
        {nombre_sucursal: `${t('branch-office')} 1`, nps:"61.5333", nombre_rubro: `${t('sector')} 2`},
        {nombre_sucursal: `${t('branch-office')} 7`, nps:"50.0292", nombre_rubro: `${t('sector')} 2`},
        {nombre_sucursal: `${t('branch-office')} 6`, nps:"45.0111", nombre_rubro: `${t('sector')} 2`},
        {nombre_sucursal: `${t('branch-office')} 4`, nps:"38.9579", nombre_rubro: `${t('sector')} 2`},
        {nombre_sucursal: `${t('branch-office')} 5`, nps:"-18.0025", nombre_rubro: `${t('sector')} 2`}
      ]
    ],
    indicador: 1
  }

  useEffect(() => {
    const value = {};
    const dataRankings = [];
    if (data) {
      if (data.indicador === undefined) {
        Object.assign(value, { rankingPasadoSeg: [], rankingActualSeg: [] });
        let datos = [];
        let actual = [];
        actual = data.rankingActual;
        if (actual.length > 12) {
          for (let i = 12; i < actual.length; i++) {
            datos[i] = actual[i];
            actual[i] = null;
          }

          dataRankings.push({
            pasado: data.rankingPasado,
            actual,
          });
          dataRankings.push({
            pasado: data.rankingPasado,
            actual: datos,
          });
        } else {
          dataRankings.push({
            pasado: data.rankingPasado,
            actual: data.rankingActual,
          });
        }
      } else {
        dataRankings.push(
          ...(data.rankingPasado.map((pasado, index) => ({
            pasado,
            actual: data.rankingActual[index],
          })))
        );
      }
      setRankings(dataRankings);
    }
  }, [t]);

  return (
    <>
      {rankings.map((ranking, index) => {
        return(
          <CompiladoTable
            key={index}
            pasado={ranking.pasado}
            actual={ranking.actual}
          />
        )
      })}
    </>
  )
};

export default RankingCard;