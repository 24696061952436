import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Encuesta from "./views/encuesta";
import mailing from "./views/mailing";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";
import Compilado from "./views/Compilado";
import Contacto from "./views/Contacto";
import Calificador from "./views/Calificador";
import Ticket from "./views/Ticket";

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/blog-overview" />,
  },
  // {
  //   path: "/round-slider",
  //   layout: DefaultLayout,
  //   component: () => (
  //     <RoundSlider
  //       value={50}
  //       onChange={(...args) => console.log(...args)}
  //     />
  //   ),
  // },
  {
    path: "/blog-overview",
    layout: DefaultLayout,
    component: BlogOverview,
  },
  {
    path: "/encuesta",
    layout: DefaultLayout,
    component: Encuesta,
  },
  {
    path: "/mailing",
    layout: DefaultLayout,
    component: mailing,
  },
  {
    path: "/contacto",
    layout: DefaultLayout,
    component: Contacto
  },

  {
    path:"/calificador",
  layout: DefaultLayout,
 component:Calificador
},


{
  path:"/tickets",
  layout: DefaultLayout,
  component:Ticket
},


  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite,
  },
  {
    path: "/add-new-post",
    layout: DefaultLayout,
    component: AddNewPost,
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors,
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview,
  },
  {
    path: "/tables",
    layout: DefaultLayout,
    component: Tables,
  },
  {
    path: "/blog-posts",
    layout: DefaultLayout,
    component: BlogPosts
  },

  {
    path:"/compilado",
    layout:DefaultLayout,
    component:Compilado
  }
];
