import React from "react";
import { Container, Row, Col,Card,CardHeader,CardBody } from "shards-react";
import PageTitle from "./../components/common/PageTitle";



//Graficos


import Sparklines from "../components/ApexCharts/Sparklines";
import AreaChart from "../components/ApexCharts/AreaStacked";
import { useMobile } from '../utils/dom';

/*
import HeatChart from "../components/ApexCharts/HeatChart";
import LineWithBar from "../components/ApexCharts/LineWithBar";
import StackedBar from "../components/ApexCharts/StackedBar";
import Column from "../components/ApexCharts/Column";
*/

//Caritas
/*
import carita_excelente from '../assets/excelente.png'; 
import carita_bueno from '../assets/bueno.png'; 
import carita_regular from '../assets/regular.png'; 
import carita_mal from '../assets/mal.png';
*/ 

//CSS
import styles from '../assets/style_dashboard.css'; 
   import Title from './../components/ApexCharts/Title';


const mailing = ({  }) => (
  
  <Container fluid className="main-content-container px-4">
    {/* Page Header */}
 <Title/>

<Sparklines />

<AreaChart style={{ marginTop:'20px' }} />

  </Container>
);


export default mailing;