import React from "react";
import Chart from 'react-apexcharts'
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { useI18n } from "../../utils/i18n";
import resources from '../../translations/mailing.i18n';
import '../../styles/area.css';

const AreaChart = () => {

  const t = useI18n({ resources })

  const optionsChart = {
    options: {
      chart: {
        stacked: true,
        events: {
          selection: function (chart, e) {
            console.log(new Date(e.xaxis.min))
          }
        },
        toolbar: {
          show: false,
        },
      },
      colors: ['#008FFB', '#00E396', '#CED4DC'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth'
      },
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.6,
          opacityTo: 0.8,
        }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left'
      },
      xaxis: {
        type: 'datetime'
      }
    },
    series: [
      {
        name: t('answered'),
        data: [
        ['2019-06-01',35],
        ['2019-06-02',27],
        ['2019-06-03',69],
        ['2019-06-04',49],
        ['2019-06-05',26],
        ['2019-06-06',79],
        ['2019-06-07',45],
        ]
      },
      {
        name: t('open'),
        data: [
        ['2019-06-01',15],
        ['2019-06-02',53],
        ['2019-06-03',71],
        ['2019-06-04',26],
        ['2019-06-05',74],
        ['2019-06-06',111],
        ['2019-06-07',180],
        ]
      },
      {
        name: t('sent'),
        data: [
        ['2019-06-01',300],
        ['2019-06-02',190],
        ['2019-06-03',140],
        ['2019-06-04',205],
        ['2019-06-05',335],
        ['2019-06-06',135],
        ['2019-06-07',225],
        ]
      }
    ],
  }

  return (
    <div style={{marginTop:'20px'}} id="chart">
      <Chart className={'my-chart-leyend'} options={optionsChart.options} series={optionsChart.series} type="area" height="350" />
    </div>
  );
}

export default AreaChart;
