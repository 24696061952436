import React from "react";
import Chart from 'react-apexcharts'
import { Card, CardHeader, CardBody } from "shards-react";

class Circle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        plotOptions: {
          radialBar: {
            hollow: {
              size: '70%',
            },
            
          },
        },
    
        labels: [this.props.title_circle]
      },
      series: [this.props.data],
    }

    
}
  render() {
    return (
     
        
          <Chart options={this.state.options} series={this.state.series} height="280px" type="radialBar" />

    )
  }
}
export default Circle;
