export default {
    en: {
        'care-evaluation': 'Assessment of care',
        'bathroom-evaluation': 'Bathroom evaluation',
        'recommend-us': 'Would you recommend us?',
        'client':'Client',
        'comment': 'Comment',
        'date':'Date',
        'price': 'Price',
        'prices': 'Prices',
        'garbage-options': 'You should provide options to separate garbage',
        'others': 'Others',
        'excellent': 'Excellent',
        'suggestions': 'Suggestions',
        'offers': 'Offers',
        'shame-wifi': 'Great, shame about the wifi', // arreglar.
        'good': 'Good',
        'bad': 'Bad',
        'variety-of-products': 'Variety of products',
        'good-movie': 'Very good movie',
        'popcorn-expensive': 'Popcorn is very expensive',
        'very-cold': 'It was very cold',
        'put-discounts': 'Lately, prices have risen a lot, put more discounts',
        'attention': 'Attention',
        'good-attention': 'Very good staff service',
        'movies': 'Movies',
        'name-customer-1': 'Daniel',
        'name-customer-2': 'Joey',
        'name-customer-3': 'Jhon',
        'name-customer-4': 'Peter',
        'last-name-customer-1': 'Smith',
        'last-name-customer-2': 'Parker',
        'last-name-customer-3': 'Thomas',
        'last-name-customer-4': 'Stabler',
        'detractors': 'Detractors',
        'empathy': 'Empathy',
        'speed': 'Speed',
        'advice': 'Advice',
        'cleaning': 'Cleaning',
        'smells': 'Smells',
        'supplies': 'Supplies',
        'location': 'Location'
    },
    es: {
        'care-evaluation': 'Evaluación de la Atención',
        'bathroom-evaluation': 'Evaluación de los baños',
        'recommend-us': '¿Nos recomendarías?',
        'comment': 'Comentario',
        'client':'Cliente',
        'date':'Fecha',
        'price': 'Precio',
        'prices': 'Precios',
        'garbage-options': 'Deberían brindar opciones para separar la basura',
        'others': 'Otros',
        'excellent': 'Excelente',
        'suggestions': 'Sugerencias',
        'offers': 'Ofertas',
        'shame-wifi': 'Genial, lástima el wifi',
        'good': 'Bueno',
        'bad': 'Malo',
        'variety-of-products': 'Variedad de productos',
        'good-movie': 'Muy buena la peli',
        'popcorn-expensive': 'Están muy caros los pochoclos',
        'very-cold': 'Me morí de frío',
        'put-discounts': 'Últimamente subieron muchos los precios, poner más descuentos',
        'attention': 'Atención',
        'good-attention': 'Muy buena la atención del personal',
        'movies': 'Cine',
        'name-customer-1': 'Daniel',
        'name-customer-2': 'José',
        'name-customer-3': 'Juan',
        'name-customer-4': 'Gonzalo',
        'last-name-customer-1': 'Quintero',
        'last-name-customer-2': 'Aranda',
        'last-name-customer-3': 'Peres',
        'last-name-customer-4': 'López',
        'detractors': 'Detractores',
        'empathy': 'Empatía',
        'speed': 'Velocidad',
        'advice': 'Asesoramiento',
        'cleaning': 'Limpieza',
        'smells': 'Olores',
        'supplies': 'Insumos',
        'location': 'Localización'
    },
    pt: {
        'care-evaluation': 'Avaliação do atendimento',
        'bathroom-evaluation': 'Avaliação do banheiro',
        'recommend-us': 'Você nos recomendaria?',
        'comment': 'Comentário',
        'client':'Cliente',
        'date':'Data',
        'price': 'Preço',
        'prices': 'Preços',
        'garbage-options': 'Vocês deviam ter opção pra separa o lixo',
        'others': 'Outros',
        'excellent': 'Excelente',
        'suggestions': 'Sugestões',
        'offers': 'Ofertas',
        'shame-wifi': 'Ótimo, só o wifi tava meio ruim',
        'good': 'Bom',
        'bad': 'Ruim',
        'variety-of-products': 'Variedade de produtos',
        'good-movie': 'Filme muito bom',
        'popcorn-expensive': 'A pipoca é muito cara',
        'very-cold': 'Eu morri de frio',
        'put-discounts': 'Ultimamente, os preços subiram muito, podiam colocar mais descontos',
        'attention': 'Atenção',
        'good-attention': 'Muito bom atendimento da equipe',
        'movies': 'Filmes',
        'name-customer-1': 'Daniel',
        'name-customer-2': 'Pedro',
        'name-customer-3': 'João',
        'name-customer-4': 'Guilherme',
        'last-name-customer-1': 'Quintero',
        'last-name-customer-2': 'Alves',
        'last-name-customer-3': 'Marcos',
        'last-name-customer-4': 'Lopes',
        'detractors': 'Detratores',
        'empathy': 'Empatia',
        'speed': 'Velocidade',
        'advice': 'Atendimento',
        'cleaning': 'Limpeza',
        'smells': 'Odores',
        'supplies': 'Insumos',
        'location': 'Localização'
    }
}