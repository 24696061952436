import React, { useRef, useEffect, useState } from 'react';
import {Card,CardBody,FormSelect} from 'shards-react';
import $ from 'jquery';
import 'round-slider';
import 'round-slider/dist/roundslider.min.css';
import '../assets/style_dashboard.css';
import { useMobile } from "../utils/dom";
import { useI18n } from '../utils/i18n';
import resources from '../translations/apex-charts.i18n';

const RoundSlider = ({ value, onChange,nombre,personalizado,min=0, ...props }) => {
  const divRef = useRef();


  useEffect(() => {
    if (!divRef.current) return;

    $(divRef.current).roundSlider({
      value,
      valueChange: ({ value }) => onChange(value,personalizado),
    });

  }, [value,personalizado])

  useEffect(() => {
    if (!divRef.current) return;
    $(divRef.current).roundSlider({
      value,
      valueChange: ({ value }) => onChange(value,personalizado),
      ...props,
      radius: 80,
      circleShape: "half-top",
      sliderType: "range",
      showTooltip: true,
      rangeColor:'blue',
      min

    });

  }, [divRef.current]);

  return (
    <div style={{
      display:'block',
      marginLeft:'5px',
      marginRight:'5px'
    }}>
      <div ref={divRef} />
      <div style={{ textAlign:'center'}}>
        <p> {nombre}</p>
      </div>
    </div>
  );
}


export const HalfCircles = ({onChange,personalizado,reciente,frecuente,volumen,onChangeTipo}) => {
  const isMobile = useMobile();
  const t = useI18n({ resources });

  return (
    <React.Fragment>
      <Card>
        <CardBody style={{ textAlign:'-webkit-center',padding:"20px",justifyContent: 'center',display: 'grid '}}>
          <div style={{ display:isMobile?'block':'inline-flex' }}>
          <RoundSlider value={reciente} personalizado={personalizado} nombre={t('recent')} onChange={(value,p)=>onChange('reciente',value,p)}/>
          <RoundSlider value={frecuente}  personalizado={personalizado} nombre={t('frequent')} onChange={(value,p)=>onChange('frecuente',value,p)}/>
          <RoundSlider value={volumen} personalizado={personalizado} nombre={t('volume')} onChange={(value,p)=>onChange('volumen',value,p)}/>
        </div>
          <FormSelect onChange={e => onChangeTipo(e.target.value)}  style={{ width:isMobile?null:'40%',display:'flex',justifyContent:'center' }}>
            <option selected={personalizado} value="Personalizado">{t('custom')}</option>
            <option value="Pestrella">{t('star-potential')}</option>
            <option value="estrella">{t('star')}</option>
            <option value="leales">{t('loyal')}</option>
            <option value="riesgo">{t('clients-at-risk')}</option>
          </FormSelect>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}


export const NPS = ({onChange,nps}) => {
  return (
    <React.Fragment>
      <Card style={{ height: '205px' }}>
        <CardBody style={{ display:'inline-flex',justifyContent:'center' }}>
          <RoundSlider min={-100} value={nps} nombre="NPS"onChange={(value,p)=>onChange('nps',value)}/>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
