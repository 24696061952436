import React from "react";
import Chart from 'react-apexcharts'
import { Card, CardHeader, CardBody } from "shards-react";
import { useI18n } from "../../utils/i18n";
import resources from '../../translations/blog-overview.i18n'


const StackedBar = ({ isMobile, title }) => {

  const t = useI18n({ resources });

  const SUCURSAL = t('branch-office');

    const optionsChart = {
      options: {
        chart: {
          stacked: true,
          stackType: '100%'
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        xaxis: {
          categories: [`${SUCURSAL} 1`, `${SUCURSAL} 2`, `${SUCURSAL} 3`, `${SUCURSAL} 4`, `${SUCURSAL} 5`, `${SUCURSAL} 6`, `${SUCURSAL} 7`],
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40
        },
        colors: ['#4FC3B3','#E9C563','#DB8916','#C22821'],
      },

      series: [{
        name: t('excellent'),
        data: [93,90,90,87,89,89,85]
      }, {
        name: t('good'),
        data: [7,8,6,7,6,6,8]
      }, {
        name: t('regular'),
        data: [0,0,2,3,2,1,3]
      }, {
        name: t('bad'),
        data: [0,2,2,3,2,4,4]
      }
    ],
    }

  return (
    <Card small className="h-100">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{title}</h6>
      </CardHeader>
      <CardBody className="pt-0" style={{ height: isMobile ? '400px' : '400px' }}>
        <Chart options={optionsChart.options} series={optionsChart.series} type="bar" height={ isMobile ? '100px' : '400px' } />
      </CardBody>
    </Card>
  );
}

export default StackedBar;
