export default function () {
  return [
    {
      title: "devices",
      to: "/blog-overview",
      htmlBefore: '<i class="material-icons">insert_emoticon</i>',
      htmlAfter: "",
    },
    {
      title: "surveys",
      to: "/encuesta",
      htmlBefore: '<i class="material-icons">question_answer</i>',
      htmlAfter: "",
    },
    {
      title: "mailing",
      to: "/mailing",
      htmlBefore: '<i class="material-icons">mail_outline</i>',
      htmlAfter: "",
    },
    {
      title: "compiled",
      to: "/compilado",
      htmlBefore: '<i class="material-icons">insert_chart</i>',
      htmlAfter: ""
    },
    {
      title: "contacts",
      to: "/contacto",
      htmlBefore: '<i class="material-icons">people</i>',
      htmlAfter: ""
    },

    {
      title: "web-qualifier",
      to: "/calificador",
      htmlBefore: '<i class="material-icons">insert_chart</i>',
      htmlAfter: ""
    },

    {
      title: "ticket",
      to: "/tickets",
      htmlBefore: '<i class="material-icons">receipt</i>',
      htmlAfter:""
    }




  ];
}
