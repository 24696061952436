import React from "react";
import Chart from 'react-apexcharts'
import { Card, CardHeader, CardBody } from "shards-react";
import { useI18n } from "../../utils/i18n";
import resources from '../../translations/apex-charts.i18n';

 const BarChart = ({isMobile}) => {
   const t = useI18n({ resources });

        const options= {
            plotOptions: {
              bar: {
                horizontal:isMobile?true:false,
                columnWidth: '35%',
                endingShape: 'rounded'
                     },
             },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10','11', '12', '13', '14', '15', '16', '17', '18', '19',  '20','21', '22', '23', '24', '25', '26', '27', '28', '29','30'],
            },

            fill: {
              opacity: 1
            },

          };

          const series= [{
            name: 'Free Cash Flow',
            data: [3, 1, 6, 6, 0, 0, 0, 3, 1, 5, 1, 3, 6, 0, 0, 0, 5, 4, 3, 1, 6, 6, 4, 4, 2, 3, 4, 1, 1, 0 ]
          }];




        return (


      <Card  className="h-100">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{t('answers')}</h6>
        </CardHeader>
        <CardBody className="pt-0">
            <Chart options={options} series={series} type="bar" height='350px'/>
            </CardBody>
      </Card>
      )

                }

                export default BarChart