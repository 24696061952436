import React from 'react';
import {Container,Row,Col} from 'shards-react';
import Title from "./../components/ApexCharts/Title";
import Cards from "./../components/ApexCharts/Cards";
import DetailsTable from "./../components/ApexCharts/VotesDetails";
import { Tag } from 'antd';
import { useI18n } from '../utils/i18n';
import resources from '../translations/tickets.i18n';

const Ticket = () => {

  const t = useI18n({ resources })

  const columnsBefore = [
    {
      title: t('status'),
      field:'estado',
      render:row => (<Tag style={{ marginTop:'5px' }} color={row.estado.color}>{row.estado.nombre}</Tag>)
    },
    {title:'ID', field:'ids', headerStyle: { padding: '0px'}},
    {
      title: t('date'),
      field:'created_at',
      render: row => (<div>{row.created_at}<Tag color={row.vto.color?row.vto.color:null}>vto {row.vto.fecha}</Tag></div>)
    },
    {title: t('priority'), field:'prioridad'},
    {title: t('category'), field:'event'},
    {title: t('responsable'), field:'resp'},
    {title: t('subject'), field:'asunto', headerStyle: { padding: '60px'}},
    {
     title:'Tags',
     field:'tag',
     render: row => row.tag ? row.tag.map((tag, index) => (<Tag key={index} style={{ marginTop:'5px' }} color={tag.color}>{tag.nombre}</Tag>)) : null,
     cellStyle: { justifyContent:'center', textAlign:'center' },
     headerStyle: { textAlign:'center' }
    },
    {title:t('client'), field: 'nombre'},
  ];

  const datos= {
    votes:[
      {
        votos:[],
        ids:1,
        created_at:'14/05/2020 10:05',
        estado: { nombre: t('opened').toUpperCase(), color:'#ff0000' },
        prioridad: t('high').toUpperCase(),
        event: t('claim'),
        resp: "Marcelo",
        asunto: t('bad-service'),
        vto: {fecha:'27/5', color:'cyan' },
        tag: [{nombre: t('attention')}],
        nombre:'Diego'
      },
      {
        votos:[],
        ids:2,
        created_at:'13/05/2020 13:05',
        vto: {fecha:'25/05', color:'cyan'},
        estado: { nombre: t('closed').toUpperCase(), color:'#2db7f5' },
        prioridad: t('medium').toUpperCase(),
        event: t('consultation'),
        resp:"Marcelo",
        asunto: t('cant-find-terms'),
        nombre: t('name-customer-1')
      },
      {
        votos:[],
        ids:3,
        created_at:'01/05/2020 10:05',
        vto:{fecha:'23/05',color:'cyan'},
        estado: { nombre: t('opened').toUpperCase(), color:'#ff0000' },
        prioridad: t('medium').toUpperCase(),
        event: t('consultation'),
        resp:"Marcelo",
        asunto: t('change-product'),
        tag:[{nombre: t('changes')}],
        nombre: t('name-customer-1')
      },
      {
        votos:[],
        ids:4,
        created_at:'10/05/2020 10:05',
        vto:{fecha:'17/05',color:'red'},
        estado: { nombre: t('opened').toUpperCase(),color:'#ff0000' },
        prioridad: t('low').toUpperCase(),
        event: t('consultation'),
        resp:"Daniela",
        asunto: t('hour-open'),
        tag:[{nombre: t('schedules')}],
        nombre: t('name-customer-2')
      },
    ]
  };

  return (
    <Container>
      <Title/>
      <Row>
        <Cards/>
      </Row>
      <Row style={{ marginTop:'10px' }}>
        <Col lg="12" md="4" sm="12" className="mb-4" >
          <DetailsTable  datos={datos} columnsBefore={columnsBefore} sinHora={true}/>
        </Col>
      </Row>
    </Container>
  );
}

export default Ticket;