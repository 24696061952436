import React from "react";
import Chart from 'react-apexcharts'
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { useI18n } from '../../utils/i18n';
import resources from '../../translations/blog-overview.i18n'



function muestraData(array, turno) {
  var series = [];
  array.map(cada_dia => {
    if(turno == 'excelente'){
      series.push(cada_dia.cant_v1);
    }
    if(turno == 'bueno'){
      series.push(cada_dia.cant_v2);
    }
    if(turno == 'regular'){
      series.push(cada_dia.cant_v3);
    }
    if(turno == 'malo'){
      series.push(cada_dia.cant_v4);
    }
  });
  return series;
}

const Column = ({ isMobile, data, title }) => {
  const t = useI18n({ resources });

  function muestraDias(array){
    var series = [];
    array.map(cada_dia => {
      const date = cada_dia.nombre;
      series.push(`${date.substring(0, date.indexOf(' '))} ${t(cada_dia.turno)}`);
    });
    return series;
  }

  const optionsChart = {
    options: {

      chart: {
        toolbar: {
          show: false,
        },
        height: '350',
        width: '100%',
        stacked: true,
        stackType: '100%'
      },
      responsive: [
        {
          breakpoint: 100,
          columnWidth: '30%',
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: 0,
        formatter: function(val) {
          return Math.round(val);
        },
        style: {
          fontSize: '10px'
          // colors: ["#ff0000"]
        }
      },
      xaxis: {
        categories: muestraDias(data),
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: 'top',
        offsetX: 0,
        offsetY: 0
      },
      colors: ['#C22821','#DB8916','#E9C563','#4FC3B3'],
    },

    series: [
      {
        name: t('bad'),
        data: muestraData(data,'malo')
      },
      {
        name: t('regular'),
        data: muestraData(data,'regular')
      },
      {
        name: t('good'),
        data: muestraData(data,'bueno')
      },
      {
        name: t('excellent'),
        data: muestraData(data,'excelente')
      },
    ],
  }

  return (
    <Card small className="h-100">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{title}</h6>
      </CardHeader>
      <CardBody className="pt-0" style={{ height: isMobile?'400px':'auto' }}>
        <Chart options={optionsChart.options} series={optionsChart.series} type="bar" height={isMobile ? '50%' : '370px'} />
      </CardBody>
    </Card>
  );

}

export default Column;
