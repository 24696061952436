import React from 'react';
import { Card, CardBody, CardHeader, Col } from 'shards-react';
import { useI18n } from '../../utils/i18n';
import resources from '../../translations/tickets.i18n';

const Cards = ()=> {

    const t = useI18n({ resources })

    const datos = [
        {titulo: t('active-tickets'), cantidad:3},
        {titulo: t('expired-tickets'), cantidad:1, color:'red'},
        {titulo: t('pending-tickets'), cantidad:1},
        {titulo: t('closed-tickets'), cantidad:1}
    ];

    return datos.map((dato,i)=> (
        <Col lg="3" key={i}>
            <Card style={{ color:dato.color?dato.color:null, marginTop:'10px' }}>
                <CardHeader>{dato.titulo}</CardHeader>
                <CardBody style={{ textAlign:'right',fontSize:'30px' }}>{dato.cantidad}</CardBody>
            </Card>
        </Col>
    ))
}

export default Cards;