import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { useI18n } from '../../utils/i18n';
import resources from '../../translations/apex-charts.i18n';
import '../../styles/area.css';
import { Card, CardBody,Col } from 'shards-react';


const visitas=[
              {nombre:{nombre:"homepage"},
              visitas:[{cantidad:"676",date:"Dia 1"},
                      {cantidad:"390",date:"Dia 2"},
                      {cantidad:"360",date:"Dia 3"},
                      {cantidad:"896",date:"Dia 4"},
                      {cantidad:"831",date:"Dia 5"},
                      {cantidad:"749",date:"Dia 6"},
                      {cantidad:"654",date:"Dia 7"}]},
              {nombre:{nombre:"Contacto"},
              visitas:[{cantidad:"114",date:"Dia 1"},
                      {cantidad:"47",date:"Dia 2"},
                      {cantidad:"87",date:"Dia 3"},
                      {cantidad:"103",date:"Dia 4"},
                      {cantidad:"210",date:"Dia 5"},
                      {cantidad:"100",date:"Dia 6"},
                      {cantidad:"123",date:"Dia 7"}]}
                      //{nombre:{nombre:"cobertura"},visitas:[{cantidad:"98",date:"08 May"},{cantidad:"105",date:"09 May"},{cantidad:"68",date:"10 May"},{cantidad:"29",date:"11 May"},{cantidad:"18",date:"12 May"},{cantidad:"89",date:"13 May"},{cantidad:"72",date:"14 May"},{cantidad:"51",date:"15 May"},{cantidad:"52",date:"16 May"},{cantidad:"46",date:"17 May"},{cantidad:"82",date:"18 May"},{cantidad:"63",date:"19 May"},{cantidad:"38",date:"20 May"}]},{nombre:{nombre:"cobertura2"},visitas:[{cantidad:"0",date:"08 May"},{cantidad:"0",date:"09 May"},{cantidad:"0",date:"10 May"},{cantidad:"150",date:"11 May"},{cantidad:"173",date:"12 May"},{cantidad:"83",date:"13 May"},{cantidad:"74",date:"14 May"},{cantidad:"56",date:"15 May"},{cantidad:"44",date:"16 May"},{cantidad:"56",date:"17 May"},{cantidad:"84",date:"18 May"},{cantidad:"60",date:"19 May"},{cantidad:"26",date:"20 May"}]},{nombre:{nombre:"gracias"},visitas:[{cantidad:"1",date:"08 May"},{cantidad:"2",date:"09 May"},{cantidad:"0",date:"10 May"},{cantidad:"3",date:"11 May"},{cantidad:"1",date:"12 May"},{cantidad:"9",date:"13 May"},{cantidad:"2",date:"14 May"},{cantidad:"1",date:"15 May"},{cantidad:"1",date:"16 May"},{cantidad:"0",date:"17 May"},{cantidad:"5",date:"18 May"},{cantidad:"0",date:"19 May"},{cantidad:"0",date:"20 May"}]},{nombre:{nombre:"Total"},visitas:[{cantidad:889,date:"08 May"},{cantidad:544,date:"09 May"},{cantidad:515,date:"10 May"},{cantidad:1181,date:"11 May"},{cantidad:1247,date:"12 May"},{cantidad:1030,date:"13 May"},{cantidad:925,date:"14 May"},{cantidad:837,date:"15 May"},{cantidad:610,date:"16 May"},{cantidad:518,date:"17 May"},{cantidad:1041,date:"18 May"},{cantidad:602,date:"19 May"},{cantidad:363,date:"20 May"}]}]}}
];
function armar_dias(data) {
  var dias = [];
  data.forEach(function(element) {
    dias.push(element.date);
  });
  //console.log(dias);
  return dias;
}

function suma(data) {
  let suma = 0;
  data.forEach(function(element) {
    suma = suma + parseInt(element.cantidad);
  });

  return suma;
}

function armar_array(data) {
  var series = [];
  data.forEach(function(element) {
    let numero = parseInt(element.cantidad);
    series.push(numero);
  });

  return series;
}

const AreaViews = ({ data }) => {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});

  const t = useI18n({ resources });

  // console.log( window.localStorage.getItem('i18nextLng') === 'pt' ? 'visualizações' : 'vistas')
  const language = window.localStorage.getItem('i18nextLng') === 'pt' ? 'visualizações' : 'vistas'
  console.log(language)

  useEffect(() => {
    setSeries([
      {
        name: 'Visitas',
        data: armar_array(data.visitas)
      }
    ]);

    setOptions(
      {
        chart: {
          sparkline: {
            enabled: true
          }
        },

        colors: ['#4FC3B3'],
        stroke: {
          curve: 'straight'
        },
        fill: {
          opacity: 0.3
        },
        xaxis: {
          type: 'date',
          categories: armar_dias(data.visitas),
          tooltip: {
            enabled: false,
            formatter: function(val, opts) {
              return Math.round(parseInt(val));
            },
            offsetY: 0,
            style: {
              fontSize: 0,
              fontFamily: 0
            }
          },
          labels: {
            show: true,
            rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: false,
            minHeight: undefined,
            maxHeight: 120
          }
        },
        x: {
          show: false
        },

        grid: {
          show: false
        },
        title: {
          text: suma(data.visitas) + ' ' + language,
          offsetX: 10,
          style: {
            fontSize: '24px',
            fontWeight: 300
          }
        },
        subtitle: {
          offsetX: 10,
          text: data.nombre.nombre
        }
      })

  }, [data, language]);

  return (
    <Card style={{ marginTop:'10px',marginBottom:'10px' }}>
      <CardBody>
        <Chart
          options={options}
          series={series}
          type="area"
          width="100%"
          height="160"
          className={'my-chart'}
        />
      </CardBody>
    </Card>
  );
};

export const VisitasCard = () => {

  if (visitas) {
    return visitas.map((visita, i) => {
      if (visita.length == 0) {
        return null;
      } else {

        return (
          <AreaViews width="100%" data={visita} key={i} />

        );
      }
    });
  }

};
