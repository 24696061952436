import React from "react";
import { Link } from "react-router-dom";
import {
  DropdownToggle,
  NavLink
} from "shards-react";

import avatar from './../../../../images/avatars/gabriel.jpeg';

export default class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  render() {
    return (
     
        <DropdownToggle disabled caret tag={NavLink} className="text-nowrap px-3">
          <img
            className="user-avatar rounded-circle mr-2"
            src={avatar}
            alt="User Avatar"
          />{" "}
          <span className="d-none d-md-inline-block">Gabriel Alvarez</span>
        </DropdownToggle>
     
    );
  }
}
