export default {
    en:{
      'date':'Date',
      'priority':'Priority',
      'category':'Category',
      'responsable': 'Responsible',
      'select-responsable': 'Select responsible',
      'select-date': 'Select date',
      'subject':'Subject',
      'client':'Client',
      'create-ticket':'Create Ticket',
      'search': 'Search',
    //   'create': 'Create',
      'low':'Low',
      'medium':'Medium',
      'high':'high',
      'expiration_date':'Expiration date',
      'notes':'Notes',
      'status':'Status',
      'opened':'Opened',
      'closed':'Closed',
      'expired':'Expired',
      'pending':'Pending',
      'active':'Active',
      'closed-tickets':'Closed Tickets',
      'expired-tickets':'Expired Tickets',
      'pending-tickets':'Pending Tickets',
      'active-tickets':'Active Tickets',
      'claim': 'Claim',
      'consultation': 'Consultation',
      'bad-service': 'I got bad service',
      "cant-find-terms": "I can't find the terms of service",
      'change-product': 'I have to make a product change',
      'hour-open': 'What hours are you open?',
      'attention': 'Attention',
      'changes': 'Changes',
      'schedules': 'Schedules',
      'reason': 'Reason',
      'register-ticket': 'Ticket registration',
      'name-customer-1': 'John',
      'name-customer-2': 'Steve',
      'rows': 'Rows',
    //   'expiration':'Expiration',
    //   'close-ticket':'Close Ticket',
    //   'closed-notes':'Close Notes',
    //   'edit':'Edit',
    //   'edit-ticket':'Edit Ticket',
    //   'result': 'Result',
    //   'positive': 'Positive',
    //   'negative': 'Negative',
    //   'are-you-sure':'Are you sure?',
    //   'yes':'Yes',
    //   'no':'No',
    //   'delete':'Delete',

    },
    es:{
      'date':'Fecha',
      'priority':'Prioridad',
      'category':'Categoría',
      'responsable': 'Responsable',
      'select-responsable': 'Seleccione responsable',
      'select-date': 'Seleccionar fecha',
      'subject':'Asunto',
      'client':'Cliente',
      'create-ticket':'Crear Ticket',
      'search': 'Buscar',
    //   'create': 'Crear',
      'low':'Baja',
      'medium':'Media',
      'high':'Alta',
      'expiration_date':'Fecha de expiración',
      'notes':'Notas',
      'status':'Estado',
      'opened':'Abierto',
      'closed':'Cerrado',
      'closed-tickets':'Tickets cerrados',
      'expired-tickets':'Tickets vencidos',
      'pending-tickets':'Tickets pendientes',
      'active-tickets':'Tickets activos',
      'claim': 'Reclamo',
      'consultation': 'Consulta',
      'bad-service': 'Me atendieron mal',
      "cant-find-terms": "No encuentro las condiciones del servicio",
      'change-product': 'Tengo que hacer un cambio de producto',
      'hour-open': '¿En qué horarios se encuentran abiertos?',
      'attention': 'Atención',
      'changes': 'Cambios',
      'schedules': 'Horarios',
      'reason': 'Motivo',
      'register-ticket': 'Alta de ticket',
      'name-customer-1': 'Juan',
      'name-customer-2': 'Esteban',
      'rows': 'Registros',
    //   'expiration':'Vencimiento',
    //   'close-ticket':'Cerrar Ticket',
    //   'closed-notes':'Notas de Cierre',
    //   'edit':'Editar',
    //   'edit-ticket':'Editar Ticket',
    //   'result': 'Resultado',
    //   'positive': 'Positivo',
    //   'negative': 'Negativo',
    //   'are-you-sure':'Estas seguro?',
    //   'yes':'Sí',
    //   'no':'No',
    //   'delete':'Eliminar',
    },
    pt:{
      'date':'Data',
      'priority':'Prioridade',
      'category':'Categoria',
      'responsable': 'Responsável',
      'select-responsable': 'Selecione o responsável',
      'select-date': 'Selecione a data',
      'subject':'Caso',
      'client':'Cliente',
      'create-ticket':'Criar Ticket',
      'search': 'Procurar',
    //   'create': 'Criar',
      'low':'Baixa',
      'medium':'Media',
      'high':'Alta',
      'expiration_date':'Data de expiração',
      'notes':'Notas',
      'status':'Estado',
      'opened':'Aberto',
      'closed':'Fechado',
      'closed-tickets':'Tickets Fechados',
      'expired-tickets':'Tickets Expirados',
      'pending-tickets':'Tickets Pendentes',
      'active-tickets':'Tickets Ativos',
      'claim': 'Alegar',
      'consultation': 'Consulta',
      'bad-service': 'Eles me trataram mal',
      "cant-find-terms": "Não consigo encontrar os termos de serviço",
      'change-product': 'Eu tenho que fazer uma mudança de produto',
      'hour-open': 'Que horas estão abertos?',
      'attention': 'Atenção',
      'changes': 'Mudanças',
      'schedules': 'Horários',
      'reason': 'Razão',
      'register-ticket': 'Registrar ticket',
      'name-customer-1': 'João',
      'name-customer-2': 'Estevão',
      'rows': 'Registros',
    //   'expiration':'Vencimento',
    //   'close-ticket':'Fechar Ticket',
    //   'closed-notes':'Notas de Fechamento',
    //   'edit':'Editar',
    //   'edit-ticket':'Editar Ticket',
    //   'result': 'Resultado',
    //   'positive': 'Positivo',
    //   'negative': 'Negativo',
    //   'are-you-sure':'Estas seguro?',
    //   'yes':'Sim',
    //   'no':'Não',
    //   'delete':'Eliminar',
    }
  }