import React from "react";
import Chart from 'react-apexcharts'
import { useI18n } from '../../utils/i18n';
import resources from '../../translations/apex-charts.i18n';
import '../../styles/area.css';


const Area = () => {

  const t = useI18n({ resources });

  const seriesTopSpark1 = [{ data: [61,45,55,76,56,57,61] }];

  const chartOptionsTopSpark1 = {
    chart: {
      sparkline: {
        enabled: true
      }
    },
    colors: ['#4FC3B3'],
    stroke: {
      curve: 'straight'
    },
    fill: {
      opacity: 0.3,
    },
    yaxis: {
      min: 0
    },
    title: {
      text: t('record'),
      offsetX: 0,
      style: {
        fontSize: '24px',
        cssClass: 'apexcharts-yaxis-title',
        fontWeight: '300'
      }
    }
  }

  return (
    <div>
      <Chart className={'my-chart'} options={chartOptionsTopSpark1} series={seriesTopSpark1} type="area" width="100%" height="160" />
    </div>
  )
}

// class Area extends React.Component{
//     constructor(props) {
//         super(props);

//         this.state = {


//           seriesTopSpark1: [{
//             data: [61,45,55,76,56,57,61]
//           }],



//           chartOptionsTopSpark1: {
//             chart: {
//               sparkline: {
//                 enabled: true
//               },
//             },
//             colors: ['#4FC3B3'],
//             stroke: {
//               curve: 'straight'
//             },
//             fill: {
//               opacity: 0.3,
//             },

//             yaxis: {
//               min: 0
//             },
//             title: {
//               text: 'Historial',
//               offsetX: 0,
//               style: {
//                 fontSize: '24px',
//                 cssClass: 'apexcharts-yaxis-title'
//               }
//             },

//           },



//             }
//           }



//       render() {
//         return (
// <div>   <Chart options={this.state.chartOptionsTopSpark1} series={this.state.seriesTopSpark1} type="area" width="100%" height="160" />

//           </div>



//         );
//       }
//     }
export default Area