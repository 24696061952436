import { NavItem, NavLink, Badge, Collapse, DropdownItem, Dropdown, DropdownMenu, DropdownToggle } from "shards-react";
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useI18n } from "../../../../utils/i18n";
import resources from '../../../../translations/languages.i18n';

export const Languages = () => {
    const t = useI18n({ resources });
    const [openDropdown, setOpenDropdown] = useState(false)
    const [currentLanguage, setCurrentLanguage] = useState(window.localStorage.getItem('i18nextLng') || undefined)
    const { i18n } = useTranslation();

    const languages = useMemo(() => [
        {label: t('spanish'), key: 'es'},
        {label: t('portuguese'), key: 'pt'},
        // {label: t('english'), key: 'en'}
    ], [t]);

    function changeLanguage(lang) {
        setCurrentLanguage(lang)
        i18n.changeLanguage(lang)
      }

    return (
        <Dropdown open={openDropdown} toggle={() => setOpenDropdown(!openDropdown)}>
            <DropdownToggle theme='gray' style={{height: '100%'}}>
                {/* <NavItem className="border-right dropdown notifications">
                  <NavLink
                  className="nav-link-icon text-center"
                //   onClick={this.toggleNotifications}
                > */}
                  <div className="nav-link-icon__wrapper">
                    <i className="material-icons"  style={{fontSize: '26px'}}>&#xe3a2;</i>
                  </div>
                {/* </NavLink>
                </NavItem> */}
            </DropdownToggle>
            <DropdownMenu>
            {languages.map((language, index) => (
              <DropdownItem
                onClick={() => changeLanguage(language.key)}
                key={language.key ? language.key : index}
                active={currentLanguage === language.key ? true : false}
              >
                {language.label}
              </DropdownItem>
            ))}
            </DropdownMenu>
        </Dropdown>
    );
}