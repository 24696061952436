import React, { useMemo } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import Title from "./../components/ApexCharts/Title";
import Area from "../components/ApexCharts/Area";
import {DetailsTable,ColumnClient} from "./../components/ApexCharts/VotesDetails";
import carita_excelente from "../assets/excelente.png";
import carita_bueno from "../assets/bueno.png";
import carita_regular from "../assets/regular.png";
import carita_mal from "../assets/mal.png";
import "../assets/style_dashboard.css";
import RadarParamsArray from "../components/ApexCharts/RadarParamsArray";
import {VisitasCard} from "../components/ApexCharts/AreaViews";

import { useMobile } from "../utils/dom";
import resources from '../translations/blog-overview.i18n';
import { useI18n } from '../utils/i18n';

const Calificador = () => {
  const isMobile = useMobile();
  const t = useI18n({ resources });

  const columnsBefore=[
    {title: t('date'), field:'created_at'},
    {title: t('client'), field: 'nombre', render: row => <ColumnClient row={row} />},
    {title: t('comment'), field: 'comentario', editable: 'onUpdate'}
  ];

  const datos = {
    columnas:[],
    votes: [
      {
        punto_de_venta: "CAJA",
        sucursal: "SHOPPING",
        Operario: null,
        has_user_context: 0,
        nombre: "Daniel",
        apellido: "Quintero",
        mail: "quinta@gmail.com",
        telefono: 3732642387,
        encuesta_datos_id: 593,
        tipo_dato: null,
        is_first_visit: null,
        referral: null,
        created_at: "2019-11-28 11:36:48",
        fecha_operacion: null,
        votos_ids: null,
        votos: [{ voto_id:1, voto: t('excellent'), pregunta: t('recommend-us'), motivo: t('speed') }],
        preguntas: null,
        motivos: null,
        comentario: t('quickly-found'),
        tag:[{ nombre:"Wifi", color:"blue" }]
      },
      {
        punto_de_venta: "CAJA",
        sucursal: "SHOPPING",
        Operario: null,
        has_user_context: 0,
        nombre:"Pepe",
        apellido: "arbol",
        mail: "arpep@gmail.com",
        telefono: 459039485,
        encuesta_datos_id: 593,
        tipo_dato: null,
        is_first_visit: null,
        referral: null,
        created_at: "2019-11-28 11:36:48",
        fecha_operacion: null,
        votos_ids: null,
        votos: [{ voto_id:1, voto: t('excellent'), pregunta: t('recommend-us'), motivo: t('simple') }],
        preguntas: null,
        motivos: null,
        comentario: t('specific'),
        tag:[{ nombre: t('movies'), color:"red" }]
      },
      {
        punto_de_venta: "CAJA",
        sucursal: "SHOPPING",
        Operario: null,
        has_user_context: 0,
        nombre: "Juan",
        apellido: "Peres",
        mail: "jp@gmail.com",
        telefono: 49348029,
        encuesta_datos_id: 593,
        tipo_dato: null,
        is_first_visit: null,
        referral: null,
        created_at: "2019-11-28 11:36:48",
        fecha_operacion: null,
        votos_ids: null,
        votos: [{ voto_id:1, voto: t('excellent'), pregunta: t('recommend-us'), motivo: t('others')}],
        preguntas: null,
        motivos: null,
        comentario: t('easy-navigation'),
        tag:[{ nombre: t('movies'), color:'red' },{ nombre: t('prices'), color:'green' }]
      },
      {
        punto_de_venta: "CAJA",
        sucursal: "SHOPPING",
        Operario: null,
        has_user_context: 0,
        nombre: "Gonzalo",
        apellido: "Lopez",
        mail: "gLop@hotmail.com",
        telefono: 948938493,
        encuesta_datos_id: 593,
        tipo_dato: null,
        is_first_visit: null,
        referral: null,
        created_at: "2019-11-28 11:36:48",
        fecha_operacion: null,
        votos_ids: null,
        votos:[{ voto_id:1, voto: t('excellent'), pregunta: t('recommend-us'), motivo: t('advice')}],
        preguntas: null,
        motivos: null,
        comentario: t('good-page'),
        tag:[{ nombre:"Ambientacion", color:"cyan" }] //! translate ambientacion en por ?
      },
      {
        punto_de_venta: "CAJA",
        sucursal: "SHOPPING",
        Operario: null,
        has_user_context: 0,
        nombre: null,
        apellido: null,
        mail: null,
        telefono: null,
        encuesta_datos_id: 593,
        tipo_dato: null,
        is_first_visit: null,
        referral: null,
        created_at: "2019-11-28 11:36:48",
        fecha_operacion: null,
        votos_ids: null,
        votos: [{ voto_id:1, voto: t('excellent'), pregunta: t('recommend-us'), motivo: t('simple') }],
        preguntas: null,
        motivos: null,
        comentario: "",
        tag:[{ nombre: t('offers'), color:'lime' }]
      },
      {
        punto_de_venta: "CAJA",
        sucursal: "SHOPPING",
        Operario: null,
        has_user_context: 0,
        nombre: null,
        apellido: null,
        mail: null,
        telefono:null,
        encuesta_datos_id: 593,
        tipo_dato: null,
        is_first_visit: null,
        referral: null,
        created_at: "2019-11-28 11:36:48",
        fecha_operacion: null,
        votos_ids: null,
        votos:[{ voto_id: 1, voto: t('excellent'), pregunta: t('recommend-us'), motivo: t('speed') }],
        preguntas: null,
        motivos: null,
        comentario: t('garbage-options'),
        tag:[{ nombre: t('suggestions'), color: 'green' }]
      },
      {
        punto_de_venta: "CAJA",
        sucursal: "SHOPPING",
        Operario: null,
        has_user_context: 0,
        nombre: null,
        apellido: null,
        mail: null,
        telefono: null,
        encuesta_datos_id: 593,
        tipo_dato: null,
        is_first_visit: null,
        referral: null,
        created_at: "2019-11-28 11:36:48",
        fecha_operacion:null,
        votos_ids:null,
        votos:[{ voto_id: 1, voto: t('excellent'), pregunta: t('recommend-us'), motivo: t('others') }],
        preguntas: null,
        motivos: null,
        comentario: t('good-attention'),
        tag:[{ nombre: t('attention'), color: 'purple' }]
      }
    ]
  };

  const series_radar1 = {serie1_title:'Promotores', serie1_data: [24, 45,20,10], serie2_title: 'Detractores', serie2_data: [35, 2, 10,40]}; //t('detractors')
  const label_radar1 = {serie1_labels: ['Simple', 'Velocidad','Asesoramiento','Otros']}; //t('speed')

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}

      <Title />
      <Row>
        <Col lg="4" md="6" sm="12" className="mb-4">
          <Card small className="h-100">
            <CardHeader className="border-bottom">
              <h6 className="m-0">{t('general-total')}</h6>
            </CardHeader>
            <CardBody className="pt-10">
              <ul
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingLeft: "0px"
                }}
                height="150px"
                width="100%"
                className={'caritaStyle'}
              >
                <li>
                  <img
                    width={isMobile ? "85%" : "70px"}
                    src={carita_excelente}
                    styles={"margin-right:10px"}
                    alt=''
                  />
                  <span>74%</span>
                </li>
                <li>
                  <img
                    width={isMobile ? "85%" : "70px"}
                    src={carita_bueno}
                    styles={"margin-right:10px"}
                    alt=''
                  />
                  <span>13%</span>
                </li>
                <li>
                  <img
                    width={isMobile ? "85%" : "70px"}
                    src={carita_regular}
                    styles={"margin-right:10px"}
                    alt=''
                  />
                  <span>5%</span>
                </li>
                <li>
                  <img
                    width={isMobile ? "85%" : "70px"}
                    src={carita_mal}
                    styles={"margin-right:10px"}
                    alt=''
                  />
                  <span>8%</span>
                </li>
              </ul>
              <h4 class={'dblock'}>{t('total-votes')}: 683</h4>
              <h4 class={'dblock'}>NPS: 61</h4>
              <Area width="100%" />
            </CardBody>
          </Card>
        </Col>
        <Col lg="4" md="4" sm="12" className="mb-4" >
          <RadarParamsArray series={series_radar1} labels={label_radar1} />
        </Col>
        <Col lg="4">
          <VisitasCard/>
        </Col>
      </Row>
      <Row>
        <Col lg="12" md="4" sm="12" className="mb-4" >
        <DetailsTable datos={datos} columnsBefore={columnsBefore}/>
        </Col>
      </Row>
    </Container>
  );
};

export default Calificador;
