import { useEffect, useState } from 'react';

export const useMobile = () => {
  const [currentResponsive, setCurrentResponsive] = useState(undefined);

  function onResize() {
    const responsive = window.innerWidth <= 840;
    if (responsive === currentResponsive) return;
    setCurrentResponsive(responsive);
  }

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return currentResponsive;
};
