export default {
    en:{
      'answers':'Answers',
      'report-from': 'Report from',
      'to': 'to',
      'record': 'Record',
      'views': 'views',
      //'detractors': 'Detractors',
      'recent': 'Recent',
      'frequent': 'Frequent',
      'volume': 'Volume',
      'custom': 'Personalized',
      'star': 'Star',
      'star-potential': 'Star potential',
      'loyal': 'Loyal',
      'clients-at-risk': 'Clients at risk',
      'average-of': 'average of',
      'sector': 'Sector',
      'branch-office': 'Branch office',
      'area':'Area',
    },
    es:{
      'answers':'Respuestas',
      'report-from': 'Reporte del',
      'to': 'al',
      'record': 'Historial',
      'views': 'vistas',
      //'detractors': 'Detractores',
      'recent': 'Reciente',
      'frequent': 'Frecuente',
      'volume': 'Volumen',
      'custom': 'Personalizado',
      'star': 'Estrella',
      'star-potential': 'Estrella potencial',
      'loyal': 'Leales',
      'clients-at-risk': 'Clientes en riesgo',
      'average-of': 'promedio de',
      'sector': 'Sector',
      'branch-office': 'Sucursal',
      'area':'Rubro',
    },
    pt:{
      'answers':'Respostas',
      'report-from': 'Relatório de',
      'to': 'a',
      'record': 'Registro',
      'views': 'visualizações',
      //'detractors': 'Detratores',
      'recent': 'Recente',
      'frequent': 'Frequente',
      'volume': 'Volume',
      'custom': 'Personalizado',
      'star': 'Estrela',
      'star-potential': 'Estrela potencial',
      'loyal': 'Leal',
      'clients-at-risk': 'Clientes em risco',
      'average-of': 'médio do',
      'sector': 'Setor',
      'branch-office': 'Filial',
      'area': 'Setor'
    }
  }