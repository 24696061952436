import React,{useState,useEffect} from "react";
import PropTypes from "prop-types";
import { Container, Row, Col,Card,CardHeader,CardBody } from "shards-react";
import PageTitle from "./../common/PageTitle";
import {getFromTo,toLocaleDateString} from './../../utils/date';
import { useI18n } from '../../utils/i18n';
import resources from '../../translations/apex-charts.i18n';

const Title=()=>{
    const [title,setTitle]=useState(null);
    const t = useI18n({ resources })

    useEffect(()=>{
        const fechas=getFromTo();
        setTitle(`${t('report-from')} ${toLocaleDateString(fechas.from)} ${t('to')} ${toLocaleDateString(fechas.to)}`)
    });

    return(
    <React.Fragment>
        <Row noGutters className="page-header py-4">
            <PageTitle title={title} subtitle="Dashboard"  />
        </Row>
    </React.Fragment>
    );
}

export default  Title;