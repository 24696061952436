export default {
    en: {
        'devices': 'Devices',
        'web-qualifier': 'Web qualifier',
        'surveys': 'Surveys',
        'mailing': 'Mailing',
        'ticket': 'Ticket',
        'contacts': 'Contacts',
        'compiled': 'Compiled'
    },
    es: {
        'devices': 'Dispositivos',
        'web-qualifier': 'Calificador web',
        'surveys': 'Encuesta',
        'mailing': 'Mailing',
        'ticket': 'Ticket',
        'contacts': 'Contactos',
        'compiled': 'Compilado'
    },
    pt: {
        'devices': 'Dispositivos',
        'web-qualifier': 'Avaliador web',
        'surveys': 'Pesquisa',
        'mailing': 'Distribuição',
        'ticket': 'Ticket',
        'contacts': 'Contatos',
        'compiled': 'Ranking'
    }
}